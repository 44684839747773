
const quickShipTimeOut = function (val, type = 'quick_ship_time') {
  let quickShipTime = 0
  if(!val) return 0
  switch(type) {
    case 'quick_ship_time':
      quickShipTime = val?.quick_ship_time
      break
    case 'expect_time':
      quickShipTime = val?.shipping_method?.expect_time
      break
    case 'days':
      quickShipTime = val?.shipping_method?.days
      break
    case 'shipping_time':
      quickShipTime = val?.shipping_method?.shipping_time
      break
    default:
      quickShipTime = val?.[type]
  }
  if(!quickShipTime) return 0
  const is = quickShipTime.indexOf('-') > -1 
  const arr = quickShipTime.match(/\d+/)
  return is 
    ? arr?.length ? +arr[0] : 0 
    : !!(+quickShipTime) ? +quickShipTime : 0
}
const formatDay2TimeStamp = function (day) {
  const today = new Date()
  let days = Number(day)
  let targetDate = new Date(today)
  targetDate.setDate(today.getDate() + days)
  const timeStamp = targetDate.getTime()

  return timeStamp
}

export {
  quickShipTimeOut,
  formatDay2TimeStamp
}
