import { getCookie, getLocalStorage, setLocalStorage } from '@shein/common-function'  
import { daEventCenter } from 'public/src/services/eventCenter/index'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

daEventCenter.addSubscriber({ modulecode: '2-32' })

export default {
  components: {
    FpxTipsDialog: () => import(/* webpackChunkName: "FpxTipsDialog_Comp" */'../vue_tpls/FpxTipsDialog.vue'),
  },
  data: () => ({
    fpxTipsCountDown: 5,
    fpxTipsTimer: null,
    fpxTipsResolve: null,
    fpxTipsMemberId: '',
    fpxTipsDialogVisible: false,
    SHOWED_FPX_TIPS_MEMBER_ID_LIST: [],
  }),
  computed: {
    /**
     * 是否展示fpx支付方式的升级银行app弹窗
     * checkout?.checkoutBFFAbtInfo 普通下单/超省卡/付费会员abt
     * checkoutAbtInfo 订单详情abt
     * abtInfo 一键购abt
     */
    isShowFpxTips() {
      const abtInfo = this.checkout?.checkoutBFFAbtInfo || this.checkoutAbtInfo || this.abtInfo
      return abtInfo?.SheinFpxTips?.param?.FpxTipsShow === 'Show'
    },
    fpxTipsDialogProps() {
      return {
        visible: this.fpxTipsDialogVisible,
        tips: this.language.SHEIN_KEY_PWA_26428,
        confirmButtonText: `${this.language.SHEIN_KEY_PWA_26440}${this.fpxTipsCountDown > 0 ? `（${this.fpxTipsCountDown}S）` : ''}`,
      }
    }
  },
  mounted() {
    this.setMemberId()
    this.SHOWED_FPX_TIPS_MEMBER_ID_LIST = getLocalStorage('SHOWED_FPX_TIPS_MEMBER_ID_LIST') || []
    this.UPGRADE_BANK_APP_PROMPT_PAYMENT_LIST = (window?.gbCommonInfo?.UPGRADE_BANK_APP_PROMPT_PAYMENTS || '').split(',')
    this._updateOldMatchCache()
  },
  methods: {
    showFpxTips(paymentMethod = '') {
      daEventCenter.triggerNotice({
        daId: '2-32-1',
      })
      this.fpxTipsDialogVisible = true
      const cacheKey = this._getFpxCacheKey(paymentMethod)
      this.SHOWED_FPX_TIPS_MEMBER_ID_LIST.push(cacheKey)
      setLocalStorage({
        key: 'SHOWED_FPX_TIPS_MEMBER_ID_LIST',
        value: this.SHOWED_FPX_TIPS_MEMBER_ID_LIST,
      })
      return new Promise((resolve) => {
        this.fpxTipsResolve = resolve
        this.fpxTipsCountDown = 5
        this.fpxTipsTimer = setInterval(() => {
          this.fpxTipsCountDown--
          if (this.fpxTipsCountDown === 0) {
            clearInterval(this.fpxTipsTimer)
            this.fpxTipsDialogVisible = false
            resolve('confirm')
          }
        }, 1000)
      })
    },
    confirmFpxTips() {
      daEventCenter.triggerNotice({
        daId: '2-32-2',
      })
      clearInterval(this.fpxTipsTimer)
      this.fpxTipsDialogVisible = false
      this.fpxTipsResolve('confirm')
    },
    cancelFpxTips() {
      daEventCenter.triggerNotice({
        daId: '2-32-3',
      })
      clearInterval(this.fpxTipsTimer)
      this.fpxTipsDialogVisible = false
      this.fpxTipsResolve('cancel')
    },
    _updateOldMatchCache () {
      const cacheKey = this._getFpxCacheKey('adyen-fpx')
      const oldKeyIndex = this.SHOWED_FPX_TIPS_MEMBER_ID_LIST?.indexOf(this.fpxTipsMemberId)
      if (oldKeyIndex > -1) {
        this.SHOWED_FPX_TIPS_MEMBER_ID_LIST.splice(oldKeyIndex, 1, cacheKey)
        setLocalStorage({
          key: 'SHOWED_FPX_TIPS_MEMBER_ID_LIST',
          value: this.SHOWED_FPX_TIPS_MEMBER_ID_LIST,
        })
      }
    },
    _getFpxCacheKey (paymentMethod = '') {
      return `${this.fpxTipsMemberId}_${paymentMethod}`
    },
    checkShowFpxTips (paymentMethod = '') {
      const cacheKey = this._getFpxCacheKey(paymentMethod)
      /**
       * 1. abt开启
       * 2. 在阿波罗配置的可以弹窗提示的支付方式内
       * 3. 没有缓存过
       */
      // console.log('checkShowFpxTips', this.isShowFpxTips, this.UPGRADE_BANK_APP_PROMPT_PAYMENT_LIST.includes(paymentMethod), !this.SHOWED_FPX_TIPS_MEMBER_ID_LIST.includes(cacheKey))
      return this.isShowFpxTips &&
      this.UPGRADE_BANK_APP_PROMPT_PAYMENT_LIST.includes(paymentMethod) &&
      !this.SHOWED_FPX_TIPS_MEMBER_ID_LIST.includes(cacheKey)
    },
    setMemberId() {
      const member_id = UserInfoManager.get({ key: 'memberId' })
      this.fpxTipsMemberId = member_id || ''
    }
  },
}
