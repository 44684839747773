<template>
  <div class="auto-cycle-container">
    <h2
      class="auto-cycle-container__main-title"
      :style="{color: showHeaderAtmosphere ? 'rgb(246, 246, 246)' : '#000'}"
    >
      {{ language.SHEIN_KEY_PWA_15245 }}
    </h2>
    <CheckoutCarousel
      ref="titleCarouselRef"
      class="auto-cycle-container__vice-title change-title"
    >
      <template
        v-for="(_, index) in [0, 1]"
        #[`item${index}`]
      >
        <h2 
          :key="index"
          class="S-title-nav__content-title change-title__item"
        >
          <Icon
            v-for="(icon, iconIndex) in allIcons"
            v-show="titleConfig[titles[index].titleType]?.icon?.name === icon.name" 
            :key="iconIndex"
            :name="icon.name"
            :color="showHeaderAtmosphere ? '#fff' : icon.color"
            size="12px"
          />
          <div class="change-title__text">
            <span
              v-if="getTitleConfig(index).before"
              class="change-title__text-normal"
              v-html="getTitleConfig(index).before"
            ></span>
            <span
              v-if="getTitleConfig(index).special"
              v-html="getTitleConfig(index).special"
            ></span>
            <span
              v-if="getTitleConfig(index).after" 
              class="change-title__text-normal"
              v-html="getTitleConfig(index).after"
            ></span>
          </div>
        </h2>
      </template>
    </CheckoutCarousel>
  </div> 
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { template, timeTransformer } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'
import CheckoutCarousel from './components/checkoutCarousel.vue'
import { formatDay2TimeStamp, quickShipTimeOut } from './common.js'
import { HeaderTitleType, TitleConfig, SaTypeMap } from './config.js'
import { C_MONTH_FORMAT_MAP, C_YEAR_FORMAT_MAP }  from 'public/src/pages/common/biz_helper/timer_format/dictionary'

export default {
  name: 'HeaderTitleAutoShow',
  components: {
    Icon,
    CheckoutCarousel
  },
  props: {
    lagreShipInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      titleConfig: TitleConfig,
      titles: [{}, {}], // 两个DOM需要的数据，titles永远只有两个
      actualUpdateData: [{ titleType: HeaderTitleType.Payment, value: '', passed: true }], // 实际需要滚动的数据，可能有很多个
      temporaryTotalType: [HeaderTitleType.Payment], // 临时统计当前标题类型的数组，用于改变isInit的值
      actualIndex: 0,
      isInit: true,
      interTimer: null,
      isClient: false,
      headerQuickShip: {}
    }
  },
  
  computed: {
    ...mapState('checkout', [
      'checkout',
      'language',
      'defaultShipping',
      'price',
      'checkoutAbtInfo'
    ]),
    ...mapGetters('checkout', ['userBehaviorAbt', 'orderInterceptByAbt', 'showShippingInHeaderTitleByAbt']),
    allIcons() {
      const icons = []
      Object.values(this.titleConfig).forEach(item => {
        if (!item.icon) return
        if (icons.find(iconItem => iconItem.name === item.icon.name)) return
        icons.push(item.icon)
      })
      return icons
    },
    currentIndex() {
      return this.$refs.titleCarouselRef?.currentIndex
    },
    nextIndex() {
      return this.$refs.titleCarouselRef?.nextIndex
    },
    showHeaderAtmosphere() {
      const { trade_event_atmosphere = '' } = this.checkoutAbtInfo.TradeEventAtmosphere?.param || {}
      return trade_event_atmosphere && trade_event_atmosphere !== 'NO'
    }
  },
  watch: {
    defaultShipping: {
      handler (newVal, oldVal) {
        if (typeof window === 'undefined') return 
        if (newVal !== oldVal) {
          const reuslt = this.handleShippingCheck()
          this.handleAutoCycleData({ titleType: HeaderTitleType.Shipping, ...reuslt })
        }
      },
      immediate: true,
      deep: true
    },
    'price.saved_total_price': {
      handler (newVal, oldVal) {
        if (typeof window === 'undefined' || !this.$refs.titleCarouselRef) return 
        if (newVal !== oldVal) {
          this.handleSaveTotalPrice(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'actualUpdateData': {
      handler (val) {
        if (this.isClient && !this.isInit && val?.length > 1 && !this.interTimer) {
          this.interTimer = setInterval(()=> {
            this.playTitleAnimation()
          }, 1500)
        } else if (val.length === 1) {
          this.playTitleAnimation()
          this.actualIndex = 1
        }
      },
      deep: true
    }
  },
  mounted() {
    this.isClient = true
    this.handleTitleReport(HeaderTitleType.Normal)
    this.handleSaveTotalPrice(this.price.saved_total_price)
  },
  beforeDestroy() {
    // 清除定时器
    clearInterval(this.interTimer)
    this.interTimer = null
  },
  methods: {
    template,
    // 渲染埋点上报
    handleHeadlineReport() {
      const modules = Object.values(HeaderTitleType)
      const hasLabel = this.actualUpdateData.filter(item => modules.includes(item.titleType) && item.passed)
     
      daEventCenter.triggerNotice({
        daId: '1-11-1-172',
        extraData: {
          scenes: 'headline_tips',
          type: hasLabel ? 3 : 1
        }
      })
    },
    // 当标题切换时，上报埋点
    handleTitleReport(type) {
      daEventCenter.triggerNotice({
        daId: '1-11-1-182',
        extraData: {
          headline_type: SaTypeMap[type]
        }
      })
    },
    /**
     * @description: 获取标题栏文案
     * @param {*} index
     * @return {*} before、special、after，其中special是滚动的文案
     */    
    getTitleConfig(index) {
      // 当前曝光的模块不满足条件时，将会展示兜底标题，因此有可能模块与标题不对应
      const { titleType, value } = this.titles[index]
      const config = this.titleConfig[titleType]

      if(!config) {
        return {}
      }

      const titleText = this.language[config.languageKey]
      let specialConfig

      // 目前动效文字就是高亮文字，不存在动效文字和高亮文字不是同一个多语言key的
      if (config.animation) {
        specialConfig = config.animation
      } else if (config.highlight) {
        specialConfig = config.highlight
      }
      if (!specialConfig) return { before: titleText }

      const val = value?.[0] || ''
      let specialText = this.template(
        val,
        this.language[specialConfig.languageKey]
      )
      
      // 黑五活动期间，标题不需要特殊高亮颜色
      if (specialConfig.color && !this.showHeaderAtmosphere) {
        specialText = `<font color="${specialConfig.color}">${ specialText }</font>`
      }
      
      if (!config.languageKey) {
        return { special: specialText }
      }

      if(Object.keys(this.headerQuickShip).length && titleType == HeaderTitleType.Shipping){
        const { quick_ship_time, desc_quick_ship_time, qs_date_type, shipping_method, qs_standard_value } = this.headerQuickShip || {}
        const isQuickship = quick_ship_time || desc_quick_ship_time
        // 0自然日 1工作日
        const dateType = isQuickship ? qs_date_type : shipping_method?.date_type
        if(this.showShippingInHeaderTitleByAbt === 'type_A'){
          dateType == 1 && (specialText = `<font color="${specialConfig.color}">${template(value?.[0], this.language.SHEIN_KEY_PWA_30752)}</font>`)
        } else if(this.showShippingInHeaderTitleByAbt === 'type_B'){
          const isQuickship =  (quick_ship_time || desc_quick_ship_time) && qs_standard_value ? true : false
          const time = isQuickship ? quick_ship_time : shipping_method?.shipping_time
          const daysText = dateType == 1 ? this.language[config.abtTypeLanguageKey?.work] : this.language[config.animation.languageKey]
          const specialText = `<font color="${specialConfig.color}">${ template(time?.split('-')?.[0], daysText) }</font>`
          const standard_value = shipping_method?.standard_value

          let percent
          // 无值不展示文案兜底
          if (isQuickship) {
            if(!qs_standard_value){
              return {}
            }else {
              [percent] = qs_standard_value.split('-')
            }
          }else {
            if(!standard_value){
              return {}
            }else {
              [percent] = standard_value.split('-')
            }
          }
          const special = template(`${percent}%`, specialText, this.language[config.abtTypeLanguageKey?.percent])
          return {
            before: '',
            special,
            after: ''
          }
        }else if (this.showShippingInHeaderTitleByAbt === 'type_C') {
          const hasNoQuickShipTime = this.checkout?.results?.carts?.carts?.filter(item => item.real_quick_ship != 1)?.length > 0 // 有非quickship时效商品
          const isNotQuickShip = this.headerQuickShip?.shipping_method?.expect_time && hasNoQuickShipTime
          const { desc_type, shipping_time, expect_time, date_type } = shipping_method
          let time
          let daysText
          let specialText
          let special
          // debugger
          if (isNotQuickShip) {
            if (desc_type == 0){
              time = `<font color="${specialConfig.color}">${shipping_time}</font>`
  
              special = date_type == 1 ? template(time, this.language[config.typeCLanguageKey?.workDay]) : template(time, this.language[config.typeCLanguageKey?.naturalDay])
            }else if (desc_type == 1){
              const isRangeTimeStamp = expect_time.indexOf('-') > -1
              if (isRangeTimeStamp) {
                const expect_time_arr = expect_time.split('-')
                const startTimeData = formatDay2TimeStamp(expect_time_arr[0])
                const endTimeData = formatDay2TimeStamp(expect_time_arr[1])
                //两段区间时间要和现在时间比都在同一年则展示月日，否则展示则展示年月日
                const sameYear = new Date(startTimeData).getFullYear() == new Date(endTimeData).getFullYear() && new Date().getFullYear() == new Date(startTimeData).getFullYear()
                
                time = 
                  `${timeTransformer({
                    time: startTimeData || '',
                    sDateMap: sameYear ? C_MONTH_FORMAT_MAP : C_YEAR_FORMAT_MAP,
                    defaultCode: sameYear ? 'D-3' : 'A-3'
                  })} ~ ${timeTransformer({
                    time: endTimeData || '',
                    sDateMap: sameYear ? C_MONTH_FORMAT_MAP : C_YEAR_FORMAT_MAP,
                    defaultCode: sameYear ? 'D-3' : 'A-3'
                  })}`
                const timeText = `<font color="${specialConfig.color}">${time}</font>`
                special = template(timeText, this.language[config.typeCLanguageKey?.rangeTimeStamp])      
              }else {
                const timeStamp = formatDay2TimeStamp(expect_time)
                const sameYear = new Date(timeStamp).getFullYear() == new Date().getFullYear() 
                //单个时间如果和现在时间比跨年则展示年月日，否则展示月日
                time = 
                  timeTransformer({
                    time: timeStamp || '',
                    sDateMap: sameYear ? C_MONTH_FORMAT_MAP : C_YEAR_FORMAT_MAP,
                    defaultCode: sameYear ? 'D-3' : 'A-3'
                  })
                const timeText = `<font color="${specialConfig.color}">${time}</font>`
                special = template(timeText, this.language[config.typeCLanguageKey?.singleTimeStamp])
              }
            }
          }else {
            time = quick_ship_time
            daysText = qs_date_type == 1 ? this.language[config.typeCLanguageKey?.workDay] : this.language[config.typeCLanguageKey.naturalDay]
            specialText = `<font color="${specialConfig.color}">${time}</font>`
            special = template(specialText, daysText)
          }
          return {
            before: '',
            special,
            after: ''
          }
        }
      }

      const slotIndex = titleText.indexOf('{0}')
      return {
        before: titleText.slice(0, slotIndex).replaceAll(' ', '&nbsp;'),
        special: specialText,
        after: titleText.slice(slotIndex + 3, titleText.length).replaceAll(' ', '&nbsp;')
      }
    },
    /**
     * @description: 需要对优惠信息进行处理
     * @param {object} val 优惠相关信息
     * @return {*}
     */    
    handleSaveTotalPrice(val) {
      const SaveTotalInfo = {
        titleType: HeaderTitleType.Saved,
        passed: +val?.amount > 0,
        value: +val?.amount
      }
      this.handleAutoCycleData(SaveTotalInfo)
    },
    /**
     * @description: 运输方式的时间进行检测 当前订单有大件商品的时候，不展示运输方式时效
     * @return {object} { passed: 标签tag, value: 运输方式的最快时间 } 
     */    
    handleShippingCheck() {
      if (!!this.lagreShipInfo?.largeShipGoods?.length) {
        return { passed: false }
      }
      let min = this.getFastestDeliveryDays(this.defaultShipping)

      if (Number.isNaN(+min) || min <= 0) {
        // 最小送达天数 <= 0 或 最小送达天数 > 10 或 没有默认运输方式（min为undefined）
        return { passed: false }
      }
      const APOLLO_ARRIVAL_DATE = this.checkout.APOLLO_ARRIVAL_DATE || 10
      // 按照headerQuickShip里的校验值跟 阿波罗配置的时间阈值比较，不符合则不展示文案
      if(this.headerQuickShip?.checkValue && this.headerQuickShip?.checkValue > APOLLO_ARRIVAL_DATE){
        return { passed: false }
      }
      return { passed: true, value: min }
    },
    /**
     * @description: 获取最快送达时间
     * @param {array} shippingMethods 当前选中的运输方式
     * @return {number} 最快送达时间
     */    
    getFastestDeliveryDays(shippingMethods) {
      if(!shippingMethods?.length) {
        this.headerQuickShip = {}
        return 0
      }
      if (this.showShippingInHeaderTitleByAbt === 'type_A') {
        return this.handleAbtIsTypeA(shippingMethods)
      }
      if (this.showShippingInHeaderTitleByAbt === 'type_B') {
        return this.handleAbtIsTypeB(shippingMethods)
      }
      if (this.showShippingInHeaderTitleByAbt === 'type_C'){
        return this.handleAbtIsTypeC(shippingMethods)
      }
      const interceptList = [0, 1, 2, 3, 7]
      let min
      // 多mall情况下
      shippingMethods?.forEach(item => {
        const { shipping_method } = item
        if (!shipping_method) return
        const { is_available, is_available_msg, fastest_delivery_days, rule_info } = shipping_method
        const isDisabled = is_available == 0
        const isDisabledMsg = is_available_msg == 9
        const case1 = !isDisabledMsg
        const case2 = this.orderInterceptByAbt && isDisabledMsg && interceptList.includes(+rule_info?.hint_type)
        const isOrderIntercept = isDisabled && (case1 || case2)
        const isFirst = typeof min === 'undefined'
        const isMin = fastest_delivery_days && +fastest_delivery_days < min
        if (!isOrderIntercept && (isFirst || isMin)) min = +fastest_delivery_days
      })
      return min
    },
    handleAbtIsTypeA(shippingMethods) {
      const isQuickShipTime = shippingMethods.some(s => s?.quick_ship_time || s?.desc_quick_ship_time)

      if(isQuickShipTime){
        const _shippingMethods = shippingMethods.filter(f => {
          return f.quick_ship_time
        })
        if (_shippingMethods.length == 0){
          this.headerQuickShip = {}
          return 0
        }
        const minObj = _shippingMethods.reduce((min, current) => {
          // min 上一个值，current 当前值
          const resultCurrent = quickShipTimeOut(current)
          const resultMin = quickShipTimeOut(min)

          if(resultCurrent == resultMin){
            const isAllNaturalDay = current?.qs_date_type == 0 && min?.qs_date_type == 0
            if(isAllNaturalDay){
              return current?.quick_ship_time.indexOf('-') == -1 ? current :  min
            } else {
              return current?.qs_date_type == 0 
                ? current 
                : min?.qs_date_type == 0 ? min : current?.quick_ship_time.indexOf('-') == -1 ? current :  min
            }
          }
          return resultCurrent < resultMin ? current : min
        }, _shippingMethods[0])
        const min = quickShipTimeOut(minObj)
        this.headerQuickShip = minObj
        this.headerQuickShip.checkValue = min
        // console.log('minObj', minObj, min)
        return min
      }else{
        const _shippingMethods = shippingMethods.filter(f => {
          return f?.shipping_method?.expect_time
        })
        if (_shippingMethods.length == 0){
          this.headerQuickShip = {}
          return 0
        }
        const minObj = _shippingMethods.reduce((min, current) => {
          const resultCurrent = quickShipTimeOut(current, 'expect_time')
          const resultMin = quickShipTimeOut(min, 'expect_time')

          if(resultCurrent == resultMin){
            return current?.shipping_method?.expect_time?.indexOf('-') == -1 ? current :  min
          }
          return resultCurrent < resultMin ? current : min
        }, _shippingMethods[0])
        // 非qs，比较值还是expect_time，但展示的取值改为shipping_time
        const min = quickShipTimeOut(minObj, 'shipping_time')
        this.headerQuickShip = minObj
        this.headerQuickShip.checkValue = quickShipTimeOut(minObj, 'shipping_time')
        // console.log('minObj', minObj, min)

        return min
      }
    },
    handleAbtIsTypeB(shippingMethods) {
      const isQuickShipTime = shippingMethods.some(s => (s?.quick_ship_time || s?.desc_quick_ship_time) && s?.qs_standard_value)
      const { APOLLOPERCENT } = this.checkout
      if(isQuickShipTime){
        //阿波罗增加时效到达百分比阈值开关，过滤出时效百分比（qs_standard_value）<阿波罗配置的时效百分比阈值
        let _shippingMethods = shippingMethods.filter((f) => { 
          if (f?.qs_standard_value && f?.quick_ship_time){
            const [ qs_standard_value ] = f?.qs_standard_value.split('-')
            return qs_standard_value >= APOLLOPERCENT
          }
          return false
        })
        if (_shippingMethods.length == 0){
          this.headerQuickShip = {}
          return 0
        }
        const minObj = _shippingMethods.reduce((min, current) => {
          // min 上一个值，current 当前值
          const resultCurrent = quickShipTimeOut(current)
          const resultMin = quickShipTimeOut(min)

          if(resultCurrent == resultMin){
            const isAllNaturalDay = current?.qs_date_type == 0 && min?.qs_date_type == 0
            if(isAllNaturalDay){
              return current?.quick_ship_time.indexOf('-') == -1 ? current :  min
            } else {
              return current?.qs_date_type == 0 
                ? current 
                : min?.qs_date_type == 0 ? min : current?.quick_ship_time.indexOf('-') == -1 ? current :  min
            }
          }
          return resultCurrent < resultMin ? current : min
        }, _shippingMethods[0])
        const min = quickShipTimeOut(minObj)
        this.headerQuickShip = minObj
        // console.log('minObj', minObj, min)
        this.headerQuickShip.checkValue = min
        return min
      }else{
        //时效百分比（standard_value）< 阿波罗配置的时效百分比阈值 && expect_time 无值的数据，不参与比较
        let _shippingMethods = shippingMethods.filter((f) => { 
          return f?.shipping_method?.standard_value >= APOLLOPERCENT && f?.shipping_method?.expect_time
        })
        if (_shippingMethods.length == 0){
          this.headerQuickShip = {}
          return 0
        }
        const minObj = _shippingMethods.reduce((min, current) => {
          const resultCurrent = quickShipTimeOut(current, 'expect_time')
          const resultMin = quickShipTimeOut(min, 'expect_time')

          if(resultCurrent == resultMin){
            return current?.shipping_method?.expect_time?.indexOf('-') == -1 ? current :  min
          }
          return resultCurrent < resultMin ? current : min
        }, _shippingMethods[0])
        const min = quickShipTimeOut(minObj, 'shipping_time')
        this.headerQuickShip = minObj
        this.headerQuickShip.checkValue = quickShipTimeOut(minObj, 'shipping_time')
        // console.log('minObj', minObj, min)
        return min
      }
    },

    handleAbtIsTypeC(shippingMethods) {
      const hasNoQuickShipTime = this.checkout?.results?.carts?.carts?.filter(item => item.real_quick_ship != 1)?.length > 0 // 有非quickship时效商品
      const notQuickShipTimeMethods = shippingMethods.filter(s => { 
        return s?.shipping_method?.expect_time
      })
      //优先取非quickship时效，这里和产品沟通了指优先取expect_time时效值；如果expect_time都没有值，就走quickship时效；如果quick_ship_time也没值，就返回0不展示
      if(hasNoQuickShipTime && notQuickShipTimeMethods.length){
        const maxObj = shippingMethods.reduce((max, current) => {
          const resultCurrent = quickShipTimeOut(current, 'expect_time')
          const resultMax = quickShipTimeOut(max, 'expect_time')
          //时效一样时取区间时间类型的
          if(resultCurrent == resultMax){
            return current?.shipping_method?.expect_time?.indexOf('-') == -1 ? max :  current
          }
          return resultCurrent > resultMax ? current : max
        }, shippingMethods[0])
        const max = quickShipTimeOut(maxObj, 'shipping_time')
        this.headerQuickShip = maxObj
        // console.log('maxObj', maxObj, min)
        this.headerQuickShip.checkValue = quickShipTimeOut(maxObj, 'shipping_time')
        return max
      }else{
        //过滤掉无quick_ship_time的项（此时走仅有quickship时效逻辑
        const _shippingMethods = shippingMethods.filter(s => { 
          return s?.quick_ship_time
        })
        if (_shippingMethods.length == 0) {
          return 0
        }
        const maxObj = _shippingMethods.reduce((max, current) => {
          // min 上一个值，current 当前值
          const resultCurrent = quickShipTimeOut(current)
          const resultMax = quickShipTimeOut(max)
 
          if(resultCurrent == resultMax){
            const isAllNaturalDay = current?.qs_date_type == 0 && max?.qs_date_type == 0
            if(isAllNaturalDay){
              return current?.quick_ship_time.indexOf('-') == -1 ? max :  current
            } else {
              return current?.qs_date_type == 1 
                ? current 
                : max?.qs_date_type == 1 ? max : current?.quick_ship_time.indexOf('-') == -1 ? current :  max
            }
          }
          return resultCurrent > resultMax ? current : max
        }, _shippingMethods[0])
        const max = quickShipTimeOut(maxObj)
        this.headerQuickShip = maxObj
        this.headerQuickShip.checkValue = max
        return max
      }
    },
    /**
     * @description: 整理需要循环播放的数据
     * @param {Object} options
     * @return {*}
     */    
    handleAutoCycleData(options){
      const { titleType, passed, value } = options
      const initOrder = [HeaderTitleType.Shipping, HeaderTitleType.Payment, HeaderTitleType.Saved] // 初始展示的顺序
      const index = this.actualUpdateData.findIndex(item => item.titleType === titleType)
     
      if (index > -1) {
        if (passed) {
          this.actualUpdateData[index] = { ...this.actualUpdateData[index], value }
        } else {
          this.actualUpdateData.splice(index, 1)
          // 如果在watch的actualUpdateData中关闭定时器，当用户在即将变化的时候操作不满足条件的标题，会有快速闪烁或者标题重叠问题
          if (this.actualUpdateData.length === 1) {
            if (this.interTimer) {
              clearInterval(this.interTimer)
              this.interTimer = null
            }
          }
        }
      } else {
        if (passed) {
          this.actualUpdateData.push({ titleType, passed, value })
        }
      }

      // 第一次展示的时候顺序是“最快送达时间”、“支付安全”、“组合优惠”
      if (this.isInit) {
        this.actualUpdateData.sort((i, j) => initOrder.indexOf(i.titleType) - initOrder.indexOf(j.titleType))
        !this.temporaryTotalType.includes(titleType) && this.temporaryTotalType.push(titleType)
        
        if (this.temporaryTotalType.length === initOrder.length) {
          this.isInit = false
          this.handleHeadlineReport()
        }
      }
    },
    /**
     * @description: 标题切换后触发相关的动画
     */
    playTitleAnimation() {
      const len = this.actualUpdateData.length
      const config = [...this.titles]

      this.actualIndex = (this.actualIndex >= len) ? 0 : this.actualIndex

      const currentChangeTitle = this.actualUpdateData[this.actualIndex]
      const { titleType, value } = currentChangeTitle
      const currentTitleType = config[this.currentIndex]?.titleType

      // 如果当前的标题和新的标题是一样的，禁止滚动
      if (currentTitleType === titleType) {
        return
      }

      config[this.nextIndex] = {
        titleType,
        ...(value ? { value: [value] } : {})
      }
      this.titles = config
      this.actualIndex++
      this.$refs.titleCarouselRef?.playAnimation()
      this.handleTitleReport(titleType)
    },
  }
}
</script>
<style lang="less" scoped>
.auto-cycle-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__main-title {
    width: 279px;
    height: 38/75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    .font-dpr(32px);
    font-weight: 700;
    line-height: 1.2;
  }
  &__vice-title {
    // width: 279px;
    width: 100;
    height: 26/75rem;
    .font-dpr(22px);
  }
}
.change-title {
  &__item {
    display: flex;
    justify-items: center;
    align-items: center;
  }
  &__text {
    display: flex;
    align-items: center;
    text-transform: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    margin-left: 4/75rem;
    line-height: 1.2;
    height: 26/75rem;
    .font-dpr(22px);
  }
  &__text-normal {
    font-weight: 400;
  }
}
</style>
