/**
 * 获取光标位置函数
 * @param dom	当前的输入框元素
 */
export function getCursortPosition(dom) {
  if (dom) {
    let pos = 0
    // IE Support
    if (document.selection && document.selection.createRange) {
      dom.focus()
      let sel = document.selection.createRange()
      sel.moveStart('character', -dom.value.length)
      pos = sel.text.length

      // Firefox support
    } else if (dom.selectionStart || dom.selectionStart == '0') {
      pos = dom.selectionStart
    }
    return (pos)
  } else {
    return 0
  }
}

/**
 * 设置光标位置函数
 * @param dom	当前的输入框元素
 * @param pos	需要设置光标的下标值
 */
export function setCursortPosition(dom, pos) {
  if (dom.setSelectionRange) {
    dom.focus()
    dom.setSelectionRange(pos, pos)
  } else if (dom.createTextRange) {
    let range = dom.createTextRange()
    range.collapse(true)
    range.moveEnd('character', pos)
    range.moveStart('character', pos)
    range.select()
  }
}

export function nextIdle() {
  return new Promise(resolve => {
    const rc = window.requestIdleCallback || window.setTimeout
    rc(() => resolve())
  })
}



export function getOrderAttributesList ({
  normalGoodsList = [],
  usedPrimeProductInfo = {},
  usedXtraProductInfo = {}
}) {
  let orderAttributesList = []

  const formatAttribute = (goodsInfo = {}) => {
    const { businessModel, business_model, companyId, company_id } = goodsInfo || {}
    return {
      businessModel: businessModel || business_model,
      companyId: companyId || company_id
    }
  }

  const uniqueBusinessInfo = (list = []) => {
    // 需去重
    let hashMap = {}
    return list.reduce((pre, cur) => {
      const hash = `${cur.businessModel}:${cur.companyId || ''}`
      if (!hashMap[hash]) {
        hashMap[hash] = true
        return [...pre, cur]
      }
      return pre
    }, [])
  }

  orderAttributesList = [
    ...normalGoodsList?.map?.(item => formatAttribute(item)).filter(v => v.businessModel != null),
  ]
  const primeAttribute = formatAttribute(usedPrimeProductInfo)
  const xtraAttribute = formatAttribute(usedXtraProductInfo)
  if (primeAttribute.businessModel != null) orderAttributesList.push(primeAttribute)
  if (xtraAttribute.businessModel != null) orderAttributesList.push(xtraAttribute)
  
  return uniqueBusinessInfo(orderAttributesList)
}

export function debuggerLog(...args) {
  if (typeof window === 'undefined') return
  if (gbCommonInfo?.NODE_SERVER_ENV === 'production') return
  try {
    console.info(
      '%cPay Debug Info:',
      'background:#35495e ; padding: 3px 3px; border-radius: 3px;  color: #fff',
      ...args,
      '\n'
    )
  } catch (e) {
    console.log(...args)
  }
}
