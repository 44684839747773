import { mergeQueryString } from '@shein/common-function'

export function getDeviceInfo() {
  return {
    colorDepth: window.screen.colorDepth,
    javaEnabled: window.navigator.javaEnabled() ? 1 : 0,
    language: window.navigator.language,
    screenHeight: window.screen.height || 0,
    screenWidth: window.screen.width || 0,
    timeZoneOffset: new Date().getTimezoneOffset(),
    userAgent: window.navigator.userAgent,
    origin: window.location.origin
  }
}

/**
 * 整合处理二次下单页url
 *
 * @export
 * @param {*} [{ billno = '' }={}]
 * @return {{
 *  pc: string,
 *  pwa: string
 * }} 
 */
export function getCheckoutAgainUrl({
  billno = '',
  checkoutType = ''
} = {}) {
  const { host, langPath } = gbCommonInfo
  const originSite = host + langPath
  const productType = getProductType({ type: checkoutType })
  if (productType === 'gift_card') {
    return {
      pc: `${originSite}/giftcard/checkout/checkout_again/${billno}`,
      pwa: `${originSite}/giftcard/checkout/again/${billno}`,
    }
  }
  if (productType === 'paid_membership') {
    return {
      pc: `${originSite}/buyprime_again/${billno}`,
      pwa: `${originSite}/user/orders/virtualDetail/${billno}`,
    }
  }
  if (productType === 'paid_shein_saver') {
    return {
      pc: `${originSite}/xtra_again/${billno}`,
      pwa: `${originSite}/user/orders/virtualDetail/${billno}`,
    }
  }
  return {
    pc: `${originSite}/checkout/checkout_again/${billno}`,
    pwa: `${originSite}/user/orders/detail/${billno}`,
  }
}

export function getProductType({ type = '' } = {}) {
  if (type === 'giftcard') return 'gift_card'
  if (type === 'buyprime') return 'paid_membership'
  if (type === 'xtra') return 'paid_shein_saver'
  if (type === 'ocp') return 'one_click_pay'
  return 'normal'
}

export function debuggerLog(...args) {
  if (typeof window === 'undefined') return
  if (gbCommonInfo?.NODE_SERVER_ENV === 'production') return
  try {
    console.info(
      '%cPay Debug Info:',
      'background:#35495e ; padding: 3px 3px; border-radius: 3px;  color: #fff',
      ...args,
      '\n'
    )
  } catch (e) {
    console.log(...args)
  }
}

export function makeForm (params, action, method) {
  if (typeof window == 'undefined') return

  $(`form[name='pay_jump_form']`).remove()
  method = method || 'post'
  let $form = $('<form name="pay_jump_form" action="' + action + '" method="' + method + '"></form')
  for (const key in params) {
    let $input = $('<input type="hidden" />')
    $input.prop('name', key)
    $input.val(params[key])
    $form.append($input)
  }
  $form.append('<input type="submit" value="Continue" style="display:none;" />')
  $('body').append($form)
  document.pay_jump_form.submit()
}
