<template>
  <div
    ref="checkoutCarouselRef"
    class="checkout-carousel__container"
  >
    <div 
      class="checkout-carousel__item"
      :class="{
        'checkout-carousel__item_set': noAniamtionIndex === 0,
      }"
    >
      <slot name="item0"></slot>
    </div>
    <div 
      class="checkout-carousel__item"
      :class="{
        'checkout-carousel__item_set': noAniamtionIndex === 1,
      }"
    >
      <slot name="item1"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CheckoutCarousel',
  data() {
    return {
      isReversed: false,
      noAniamtionIndex: -1,
      // 映射关系为 位置: translateY
      locationYMap: [
        {
          last: '-100%',
          current: 0,
          next: '100%'
        },
        {
          last: '-200%',
          current: '-100%',
          next: 0
        }
      ],
      // 防止动画重复播放，导致错乱
      finished: true
    }
  },
  computed: {
    currentIndex() {
      // 当正序时，isReversed 为 false，currentIndex 为 0
      // 当翻转时，isReversed 为 true，currentIndex 为 1
      return Number(this.isReversed)
    },
    nextIndex() {
      return Number(!this.isReversed)
    },
  },
  methods: {
    playAnimation() {
      if (!this.finished) return
      this.$nextTick(() => {
        this.finished = false
        const elements = this.$refs.checkoutCarouselRef.children
        const current = elements?.[this.currentIndex]
        const next = elements?.[this.nextIndex]
        if (!current || !next) return
        // 当前的标题上移到标题栏上方
        current.style.transform = `translateY(${this.locationYMap[this.currentIndex].last})`
        // 下一个标题上移到标题栏
        next.style.transform = `translateY(${this.locationYMap[this.nextIndex].current})`
        setTimeout(() => {
          this.noAniamtionIndex = this.currentIndex
          // 已被移至上方的标题移到标题栏下方，等待下一次切换
          current.style.transform = `translateY(${this.locationYMap[this.currentIndex].next})`
          current.style.top = '0'
          setTimeout(() => {
            this.noAniamtionIndex = -1
            this.finished = true
            this.isReversed = !this.isReversed
            this.$emit('callBackAfterAnimation')
          }, 100)
        }, 600)
      })
    },
    reset() {
      this.isReversed = false
      const elements = this.$refs.checkoutCarouselRef.children
      const current = elements?.[this.currentIndex]
      const next = elements?.[this.nextIndex]
      current.style.top = 0
      current.style.transform = 'none'
      next.style.top = 0
      next.style.transform = 'none'
    }
  },
  emits: ['callBackAfterAnimation']
}
</script>
<style lang="less" scoped>
.checkout-carousel {
  &__container {
    position: relative;
    overflow: hidden;
  }
  &__item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.4s;
    position: relative;

    &_set {
      transition: none;
    }
  }
}
</style>
