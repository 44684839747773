/* globals shem_caculate_data */
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { couponCodeTips } from 'public/src/pages/checkout/config/tools.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import analysisCoupon from 'public/src/pages/checkout/components/analysis_coupon.js'
import { isToLowerCase } from '../config/tools'
import { getQueryString } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
const { IS_RW } = gbCommonInfo

export default {
  computed: {
    ...mapState('checkout', [
      'price',
      'coupon',
      'checkout',
      'language',
      'loading',
      'couponGift',
      'paymentCouponList',
      'couponsApply',
      'usableCouponList',
      'couponPrivacyItem',
      'is_manual_usedCoupon',
      'isPrimeMember',
      'updatingPrice',
      'checkoutAbtInfo',
      'autoCouponInfo',
      'bestCouponInfo',
      'bestCoupon',
      'usedXtraCode',
      'autoPrimeReadCoupon',
      'autoXtraReadCoupon',
      'virtualCouponInfo',
      'usedPrimeCode',
      'autoCulateApi',
      'buyNowNo',
      'isShowTokenPay',
      'handlerSelectBin',
      'tokenBinList',
      'defaultSelectTokenPay',
      'isShowTokenFrontInstallment',
      'status',
      'installmentSelectBin',
      'isUseNewCard',
      'couponBinList'
    ]),
    ...mapGetters('checkout', ['isCouponAutoUse', 'first_coupon_code', 'isShowCouponGoodsAbt', 'recommendCouponShowAbt', 'isSelectTokenPay', 'xtraAutoController']),
    showCouponUseGuide() {
      // 满足以下所有条件时，展示引导用户去用券的提示：
      // 下单页券列表接口返回的最优券组合的预计节省金额字段XXXXXX>0
      // 当前下单页没有应用优惠券，即计算价格接口返回的已使用优惠券字段为空
      const applyCouponsSuccess = this.coupon?.applyCouponsSuccess || []

      return +this.bestCoupon?.discount_price?.amount > 0 && !applyCouponsSuccess.length
    },
    // 站点mall 多mall站样式按多mall来. 
    isSiteMallList() {
      return this.checkout?.results?.mallInfo?.mallInfoForSite?.length > 1
    },
    couponFlag() {
      return this.coupon.costShow && this.price.coupon
    },
    checkCouponPrice() {
      const { amount: cAmount, amountWithSymbol: cAmountSymbol } = this.price.couponPrice || {}

      return +cAmount > 0 ? cAmountSymbol : ''
    },
    applyCouponsSuccess() {
      return this.coupon.applyCouponsSuccess.map(item => item.couponCode)
    },
    showCouponPrivacyItem() {
      return this.checkoutAbtInfo?.['BuyTc']?.param === 'ShowBuyTc'
    },

    nowBinsOpts() {
      let binsOpt = {}
      if (this.isShowTokenPay || this.isShowTokenFrontInstallment) {
        const routerPayCardData = (this.checkout?.results?.paymentMethods || [])?.find(item => item?.code === 'routepay-card')
        const installmentRouterPayCardData = (this.checkout?.results?.paymentMethods || [])?.find(item => item?.code === 'routepay-cardinstallment')

        let tempList = []
        if (this.isShowTokenPay) {
          tempList = [...(routerPayCardData?.card_token_list || [])]
        }
        if (this.isShowTokenFrontInstallment) {
          tempList = [...tempList, ...(installmentRouterPayCardData?.card_token_list || [])]
        }
        binsOpt.bins = [...new Set(tempList.map(item => item?.card_bin).filter(item => item && item?.length > 0))]
        binsOpt.need_select = ['routepay-card', 'routepay-cardinstallment'].includes(this.status?.cardPay) ? 1 : 0
        binsOpt.selected_bin = ''
        if (this.status?.cardPay === 'routepay-card') {
          binsOpt.selected_bin = (this.handlerSelectBin && binsOpt.need_select === 1) ? this.handlerSelectBin : ''
        }
        if (this.status?.cardPay === 'routepay-cardinstallment') {
          binsOpt.selected_bin = (this.installmentSelectBin && binsOpt.need_select === 1 && !this.isUseNewCard) ? this.installmentSelectBin : ''
        }
      }

      return binsOpt
    },
    // abt 是否展示 token 支付
    isShowTokenPaymentByAbt() {
      const { webshowTokenFront = {} } = this.checkout?.abtInfo || {}
      return webshowTokenFront?.param?.webshowTokenFrontStatus === '1' || false  // 1: 展示token前置功能
    },

    isShowTokenPayment() {
      // 后端字段是否展示 token 支付
      let routepayPayment = this.checkout?.results?.paymentMethods?.find(
        item => {
          // shieldAddCard 0代表b不屏蔽，则走abt的判断逻辑
          return item?.code === 'routepay-card' && item?.shieldAddCard === 0
        }
      )
      return (
        routepayPayment &&
        this.isShowTokenPaymentByAbt &&
        (routepayPayment.card_token_list || [])?.length > 0
      )
    }
  },
  methods: {
    ...mapActions('checkout', ['updateCart', 'orignMallCacuTotal', 'filterCouponParams']),
    ...mapMutations('checkout', ['assignState', 'changeParamsStatus', 'updateState', 'upDatePrimeSceneStatus', 'upDateSceneStatusNew']),
    applyBestCoupon(couponCode) {
      // 手动用券后赋值，包括买券自动用券场景
      this.assignState({
        is_manual_usedCoupon: 1,
        showShipSwitch: false
      })

      const { applyCoupons = [] } = this.coupon
      const newCoupon = couponCode.toLowerCase()

      this.changeParamsStatus({
        type: 'coupon',
        params: {
          val: newCoupon,
          err: '',
          applyCoupons: applyCoupons.concat(newCoupon),
          errorCoupon: '',
          errorCouponList: []
        }
      })

      shem_caculate_data.coupon = this.coupon.applyCoupons.join()
      this.getUpdateCoupon({
        add_coupon: this.couponsApply ? this.coupon.val : (this.coupon.lastAddCoupon || ''),
        latest_coupon: '',
        coupon: this.coupon.val
      })
    },
    newApplyCoupon({ item }, opt = {}) {
      // 手动用券后赋值，包括买券自动用券场景
      this.assignState({
        is_manual_usedCoupon: 1,
        showShipSwitch: false
      })
      const { type, isSelected, isClicked, isAddCoupon = this.couponsApply } = opt
      const { applyCoupons = [] } = this.coupon
      const { coupon, mall = {}, store = {} } = item
      const store_code = store.store_code || ''
      const mall_code = mall.mall_code || ''
      const newCoupon = coupon.toLowerCase()
      let couponListStatus = {
        status: 'cancel',
        statusGa: 'CancelSelect',
      }

      this.changeParamsStatus({
        type: 'coupon',
        params: {
          val: newCoupon,
          err: '',
          errorCoupon: '',
          errorCouponList: []
        }
      })
      let isCancelled = applyCoupons.includes(newCoupon)
      if (isClicked) {
        // 如果是点击勾选，需要额外判断该张券是否已被使用，因为券码不唯一
        isCancelled = isSelected && isCancelled
      }
      if (isCancelled) {
        // 已使用，第二次点击将其取消
        this.changeParamsStatus({
          type: 'coupon', params: {
            val: '',
            applyCoupons: applyCoupons.filter(item => item != newCoupon)
          }
        })
        analysisCoupon.clickCancelCoupon()
      } else {
        this.changeParamsStatus({
          type: 'coupon',
          params: { applyCoupons: applyCoupons.concat(newCoupon) }
        })
        couponListStatus = {
          status: 'selected',
          statusGa: 'Select',
        }
      }
      if (type == 'clickCoupon') {
        daEventCenter.triggerNotice({
          daId: '1-11-1-28',
          extraData: {
            type: 'choose',
            store_code,
            mall_code,
            ...couponListStatus
          }
        })
      }


      shem_caculate_data.coupon = this.coupon.applyCoupons.join()
      // isAddCoupon 是否为手动输入, 最后选取的券码
      this.getUpdateCoupon({
        add_coupon: isAddCoupon ? this.coupon.val : (this.coupon.lastAddCoupon || ''),
        latest_coupon: this.coupon.val,
      }, { ...opt })
    },
    exposeUsedCouponGoods({ couponCode, applySuccessCoupon, usableCouponList }) {
      if (!couponCode || !applySuccessCoupon.length || !usableCouponList.length) return
      const applyCoupons = applySuccessCoupon.map(item => item.couponCode.toLowerCase()) || []
      const lastCouponSuccess = applyCoupons.includes(couponCode.toLowerCase())
      if (!lastCouponSuccess) return

      const usedCouponData = usableCouponList.filter(item => item.coupon.toLowerCase() == couponCode.toLowerCase()) || []
      const mall_cart_coupon_goods_lists = usedCouponData?.[0]?.mall_cart_coupon_goods_lists || []
      daEventCenter.triggerNotice({
        daId: '1-11-1-97',
        extraData: {
          coupon_code: couponCode,
          goods_data: !mall_cart_coupon_goods_lists.length ? '0' : '1'
        }
      })
    },

    updateCouponListOnly(params = {}, opt = {}) {
      const couponAutoUseData = this.isCouponAutoUse ? { coupon: '', is_coupon_auto_use: 1, first_coupon_code: this.first_coupon_code } : {}
      const couponNewParams = shem_caculate_data?.prime_product_code ? { is_buy_club: 1, product_code: shem_caculate_data?.prime_product_code } : this.isPrimeMember ? {} : { is_buy_club: 0, product_code: '' }
      const { couponData } = opt

      const options = {
        ...shem_caculate_data,
        ...params,
        ...(couponData ?? couponAutoUseData),
        coupon: this.checkout.mall_caculate_info?.coupon?.map(item => item?.couponCode?.toLowerCase()) || [],
        add_coupon: this.usableCouponList?.find(coupon => coupon.is_add == 1)?.coupon?.toLowerCase() || '',
        shein_club: couponNewParams,
        is_now_buy: !!this.buyNowNo ? 1 : 0,
        checkout_no: this.buyNowNo,
        ...this.nowBinsOpts
      }

      schttp({
        method: 'POST',
        url: '/api/coupon/getOnlyCouponListForOrderStore/get',
        data: options
      }).then(res => {
        this.updateCouponList(res)
        this.handleCouponAvaliableTip()
      })
    },
    updateCouponList(res) {
      if (res.code == '0' && res.info) {
        let {
          bestCoupon, // 算法最优券
          usableCouponList = [], // 未用券
          disabledCouponList = [] // 不可用券
        } = res.info

        usableCouponList = this.filterRecommend(usableCouponList)
        disabledCouponList = this.filterRecommend(disabledCouponList)

        // 待更新的State
        const watingState = {
          usableCouponList,
          disabledCouponList,
          isShowCouponError: false,
          bestCoupon,
        }

        this.assignState({ ...watingState })
      }
    },
    clearCouponUpdateCalculator(opt) {
      // 清除已有券信息 更新券列表 & 价格
      shem_caculate_data.coupon = ''
      this.getUpdateCoupon({}, opt)
    },
    getUpdateCoupon(params = {}, opt = {}) {
      this.changeParamsStatus({ type: 'codConfirm', params: { applyType: 'coupon' } })
      const { isFirst, cb, type, isPrime, cancellation, noLoading, loadingInfo = {}, isXtra } = opt
      const couponAutoUseData = this.isCouponAutoUse ? { coupon: '', is_coupon_auto_use: 1, first_coupon_code: this.first_coupon_code } : {}
      if (!noLoading) this.assignState({ loading: true })
      let couponNewParams = shem_caculate_data?.prime_product_code ? { is_buy_club: 1, product_code: shem_caculate_data?.prime_product_code } : this.isPrimeMember ? {} : { is_buy_club: 0, product_code: '' }

      // 在没勾选进入勾选且没自动用券的情况，触发塞入会费券
      const { visibleMyCouponListMadal, currentClickType, newPrimeProductList, couponVirtualCode } = this.virtualCouponInfo

      if (!this.isCouponAutoUse && !visibleMyCouponListMadal) {
        // 超省卡自动续费改动
        if (this.usedXtraCode && currentClickType == 'xtra' && this.checkout.RiGHT_COUPON_AUTO == 'on') {
          const coupon_code = this.xtraAutoController.xtraAllproduct?.find(f => f.product_code === this.usedXtraCode)?.product_dues_coupon_rights?.coupon_code
          const couponCode = shem_caculate_data.coupon.split(',').filter(f => f)
          if (!couponCode.includes(coupon_code) && coupon_code) {
            couponCode.push(coupon_code)
            shem_caculate_data.coupon = couponCode.join(',')
          }
          // 当前包没会费券，但是券汇总里有券，需要剔除掉
          if (!coupon_code && couponVirtualCode.some(s => couponCode.includes(s))) {
            this.filterCouponParams({ scene: 'xtra' })
          }
        }
        if (this.usedPrimeCode && currentClickType == 'prime' && this.checkout.RiGHT_COUPON_AUTO == 'on') {
          const coupon_code = newPrimeProductList?.find(f => f.product_code === this.usedPrimeCode)?.product_dues_coupon_rights?.coupon_code
          const couponCode = shem_caculate_data.coupon.split(',').filter(f => f)
          if (coupon_code && !couponCode.includes(coupon_code)) {
            couponCode.push(coupon_code)
            shem_caculate_data.coupon = couponCode.join(',')
          }
          // 当前包没会费券，但是券汇总里有券，需要剔除掉
          if (!coupon_code && couponVirtualCode.some(s => couponCode.includes(s))) {
            this.filterCouponParams({ scene: 'prime' })
          }
        }
      }

      this.orignMallCacuTotal({
        type: 'coupon',
        cancellation,
        loadingInfo,
        opt: {
          ...shem_caculate_data,
          ...params,
          ...couponAutoUseData,
          shein_club: couponNewParams,
          is_now_buy: !!this.buyNowNo ? 1 : 0,
          checkout_no: this.buyNowNo,
          ...this.nowBinsOpts
        },
        mallUrl: `/api/coupon/getCouponListForOrderStore/get`,
        cb: async (res, newRes, options) => {
          this.assignState({
            loading: false,
            isCancelXtra: false
          })

          const { coupon = {}, calculate = {} } = newRes

          // 处理异常
          let errorInfo = {}
          let couponCode = 0
          if (calculate.code != 0) errorInfo = calculate
          if (coupon.code != 0) errorInfo = coupon

          if (!(errorInfo.code > 0)) {
            if (!this.isCouponAutoUse) {
              this.exposeUsedCouponGoods({ couponCode: params?.latest_coupon || '', applySuccessCoupon: calculate?.info?.coupon || [], usableCouponList: coupon?.info?.usableCouponList || [] })
            }
            // 更新券使用成功信息
            this.updateCouponRes(calculate, opt)
            const beUsedCoupon = calculate?.info?.coupon?.map(item => item.couponCode.toLowerCase()) || []
            // 更新券列表
            this.newUpdateCouponModule(coupon, {
              beUsedCoupon: beUsedCoupon.join(','),
              ...opt
            })
            if (this.autoCulateApi) {
              this.assignState({
                autoCulateApi: false
              })
              this?.$toast(this.language?.SHEIN_KEY_PWA_31377)
            }


            this.checkCouponCost(calculate, opt)
          } else {
            couponCode = errorInfo.code
            if (!isFirst) {
              this.updateCouponErr(errorInfo, { ...options, ...opt, ...params })
            }

            this.changeParamsStatus({
              type: 'coupon', params: {
                errorCouponList: coupon.code == 0 ? coupon.info?.usableCouponList || [] : [],
              }
            })
          }

          // 点击优惠券按钮埋点
          if (this.couponsApply) {
            daEventCenter.triggerNotice({
              daId: '1-11-1-47',
              extraData: {
                apply_result: couponCode == 0 ? 1 : 0,
                coupon_code: this.coupon.val || '',
                errormessage: couponCode
              }
            })
            this.assignState({ couponsApply: false })
          }

          // 使用/取消会员包失败
          if (typeof cb == 'function' && (isPrime || isXtra)) {
            cb({
              errCode: errorInfo.code > 0
            })
          }
          // 初始cb
          if (typeof cb == 'function' && (type == 'originCallBack' || type == 'clickCoupon' || type == 'modifyCart')) {
            cb(res)
          }

          // 300627: 入参支付方式和卡bin优惠优惠券限制的支付方式无交集
          // 302458: 入参支付方式和卡bin优惠优惠券限制的支付方式一致，但是卡bin不一致
          if (['302458', '300627'].includes(calculate.code)) {
            this.changeParamsStatus({
              type: 'coupon',
              params: {
                errorCoupon: params?.latest_coupon || ''
              }
            })

            this.assignState({
              showPaymentCoupon: false
            })


            const nowCouponsList = (coupon?.info?.usableCouponList || [])?.filter(item => item?.card?.card_codes?.length > 0)?.map(item => item?.coupon)
            const routePayCardBinList = ((this?.checkout?.results?.paymentMethods || []).find(item => item.code === 'routepay-card')?.card_token_list || [])?.map(item => item?.card_bin) || []
            schttp({
              method: 'POST',
              url: '/api/coupon/validBin/get',
              data: {
                coupon_codes: nowCouponsList,
                bin_list: routePayCardBinList
              }
            }).then((res) => {
              if (res?.code == 0) {
                const couponBinList = res?.info?.coupon_bin_list || []
                this.assignState({
                  couponBinList: couponBinList
                })

                let nowBinList = []

                const getNowBinList = () => {
                  (couponBinList || []).forEach(item => {
                    if (opt?.coupon?.includes(item?.coupon_code)) {
                      nowBinList = [...new Set([...nowBinList, ...item?.bin_list])]
                    }
                  })
                }

                getNowBinList()

                const couponBinData = couponBinList.find(item => item?.coupon_code === params?.latest_coupon)
                const isHadUsefulToken = (couponBinData?.bin_list || [])?.length > 0
                if (isHadUsefulToken) {
                  if (this.isShowTokenPayment) {
                    this.assignState({
                      isShowBinCouponsDrawer: true
                    })
                  } else {
                    let routepayCardPayment =
                      this.checkout?.results?.paymentMethods?.find(
                        item => item.code == 'routepay-card' && item.enabled == 1
                      )
                    if (routepayCardPayment) {
                      this.assignState({
                        isShowBinCouponsDrawerInNormalPay: true
                      })
                    }
                  }
                } else {
                  calculate.code == '300627' && this.assignState({
                    showPaymentCoupon: true
                  })
                  this?.$toast(calculate?.tips || '')
                }
              } else {
                calculate.code == '300627' && this.assignState({
                  showPaymentCoupon: true
                })
                this?.$toast(calculate?.tips || '')
              }
            })
          }
        }
      })
    },
    updateCouponErr(errorInfo, opt) {
      let error = ''
      let costShow = false
      const { notTips } = opt
      // 多张券，保留之前使用成功的券
      const { applyCouponsSuccess } = this.coupon
      if (applyCouponsSuccess?.length) {
        const newApplyCoupons = applyCouponsSuccess.map(item => {
          return item.couponCode.toLowerCase()
        })
        this.changeParamsStatus({
          type: 'coupon', params: {
            applyCoupons: newApplyCoupons
          }
        })

        shem_caculate_data.coupon = newApplyCoupons.join()
        costShow = true
      } else {
        this.changeParamsStatus({
          type: 'coupon', params: {
            applyCoupons: [],
            val: ''
          }
        })

        shem_caculate_data.coupon = ''
      }

      if (errorInfo.code == 300627) {
        if (!this.paymentCouponList.length) {
          error = this.language.SHEIN_KEY_PWA_17175
        }
      } else if (['302458'].includes(errorInfo.code)) {
        error = ''
      } else if (['300438', '300439'].includes(String(errorInfo.code))) {
        error = this.language.SHEIN_KEY_PWA_31379
      } else {
        error = couponCodeTips({
          language: this.language,
          res: errorInfo,
          text: errorInfo.tips
        })
      }

      this.changeParamsStatus({
        type: 'coupon', params: {
          err: error,
          costShow,
          errorCoupon: opt?.latest_coupon || '',
        }
      })

      if (error && !notTips) {
        this.$toast(error)
      }

      daEventCenter.triggerNotice({
        daId: '1-11-1-24',
        extraData: {
          coupon: '',
          code: errorInfo.code || '',
          msg: errorInfo.msg || ''
        }
      })
    },
    filterRecommend(list) {
      return list?.filter(item => {
        return !this.recommendCouponShowAbt || item.list_type != 2 || item.is_add == 1
      })
    },
    exposeAutoCoupons() {
      if (typeof window == 'undefined') return
      const auto_coupon = getQueryString({ key: 'auto_coupon' }) || ''
      const autoUseByAbt = this.checkout.abtInfo?.Placeordercouponoptimization?.param?.place_order_coupon_optimization == 'on' ? true : false
      // 如果在实验组，在点击checkout和到达下单页时，均没有自动选中券码，则报 0
      // 在购物车页点击checkout 时有最优券券码， 进入下单页时没有自动用券券码，则报 1
      // 在购物车页点击checkout 时没有最优券券码， 进入下单页时有自动用券券码，则报 2
      // 在购物车页点击checkout 时有最优券券码， 进入下单页时有自动用券券码，但前后选中的券码不一致，则报 3 （如点击checkout 自动选中的券为A+B+C，到下单页时选中的券码是A+B+D）
      // 在购物车页点击checkout 时有最优券券码， 进入下单页时有自动用券券码，前后选中的券码完全一致，则报 4   （如点击checkout 自动选中的券为A+B+C，到下单页时选中的券码是A+B+C）
      // 非实验组，报 “-”
      let auto_coupon_res = '-'
      const applyCouponsSuccess = this.coupon?.applyCouponsSuccess || []
      if (autoUseByAbt) {
        if (!auto_coupon) { // 在购物车页点击checkout 时没有最优券券码
          auto_coupon_res = !!applyCouponsSuccess.length ? '2' : '0'
        } else {
          if (!applyCouponsSuccess.length) {
            auto_coupon_res = '1'
          } else {
            auto_coupon_res = applyCouponsSuccess?.map(item => item.couponCode?.toLowerCase())?.join(',') == auto_coupon.toLowerCase() ? '4' : '3'
          }
        }
      }
      daEventCenter.triggerNotice({
        daId: '1-11-1-148',
        extraData: {
          automatically_result: auto_coupon_res
        }
      })
    },
    // 上报自动使用指定运输方式运费券
    reportShippingCoupon() {
      daEventCenter.triggerNotice({
        daId: '1-11-1-172',
        extraData: {
          scenes: 'shipping_coupon_stackable',
          type: this.coupon?.applyCouponsSuccess?.some(coupon => (
            ![53, 55].includes(+coupon.typeId) && coupon.applyFor == 9
          )) ? 1 : 0
        }
      })
    },
    // 首次进入页面的券逻辑（该模块应该仅包括自动用券引导提示）
    handleFirstCouponTips(isFirst) {
      if (!isFirst) return

      // 自动用券提示
      this.exposeAutoCoupons()
      const applyCouponsSuccess = this.coupon?.applyCouponsSuccess || []
      if (!this.autoCouponInfo.alreadyShowed && applyCouponsSuccess.length) {
        this.changeParamsStatus({
          type: 'autoCouponInfo',
          params: {
            coupon: applyCouponsSuccess,
            couponGift: this.couponGift,
            show: true,
          }
        })
        this.changeParamsStatus({
          type: 'coupon',
          params: {
            isAutoUse: true
          }
        })
      }
      if(this.autoCouponInfo?.coupon?.length && typeof window !== 'undefined'){
        this?.$toast(this.template(this.autoCouponInfo?.coupon?.length, this.language?.SHEIN_KEY_PWA_24712), 3000)
      }
      

      // 用券提示优先级高于支付引导弹窗,执行完券提示后再初始化安全支付引导
      this.assignState({ canInitPayGuide: true })
    },
    handleCouponAvaliableTip() {
      let couponAvaliableTip = ''
      if (!!this.usableCouponList.length) {
        // 只有 shein 需要更换文案，romwe 维持原来的文案
        const tipsText = IS_RW ? this.language.SHEIN_KEY_PWA_25057 : this.language.SHEIN_KEY_PWA_25056
        const bestCouponTips = this.template(this.bestCoupon?.discount_price?.amountWithSymbol, tipsText)
        let primeCouponList = this.usableCouponList.filter(item => item.real_type_id == 53) || []
        // 对于用券入口提示，只有 shein 不再判断 ab，romwe 还要继续进行 abt
        const showGuide = IS_RW ? false : this.showCouponUseGuide
        // 优先级顺序 付费会员 > 最优券 > 其他
        if (!!primeCouponList.length) {
          couponAvaliableTip = this.language.SHEIN_KEY_PWA_22736
        } else {
          couponAvaliableTip = showGuide ? bestCouponTips : this.template(this.usableCouponList.length, this.language.SHEIN_KEY_PWA_22585)
        }
      }
      this.assignState({ couponAvaliableTip })
    },
    exposeRecommendCouponInfo() {
      if (this.bestCouponInfo.alreadyExposeCouponInfo) return

      this.changeParamsStatus({
        type: 'bestCouponInfo',
        params: {
          alreadyExposeCouponInfo: true,
        }
      })

      daEventCenter.triggerNotice({
        daId: '1-11-1-155',
        extraData: {
          coupon_info: this.bestCoupon ? this.bestCoupon?.is_real_best : '-'
        }
      })
    },
    // 更新优惠券信息
    newUpdateCouponModule(res, opt) {
      const { isFirst, notTips, isXtra } = opt

      if (res.code == '0' && res.info) {
        this.updateCouponList(res)
        if (!this.virtualCouponInfo.apiLock) { this.updateSceneVirtual() }
        this.exposeRecommendCouponInfo()

        // 券提示信息文案（若券列表不空，且当前没有没有使用任何优惠券）
        this.handleCouponAvaliableTip()

        // 更新返回成功状态
        this.changeParamsStatus({ type: 'coupon', params: { err: '', errorCoupon: '', errorCouponList: [] } })

        this.handleFirstCouponTips(isFirst)
        // 券使用成功是否关闭弹窗,以及提示
        // 购买付费会员自动用券场景、购买超省卡场景 增加使用成功提示
        if (this.isCouponAutoUse) {
          const applyCouponsSuccess = this.coupon?.applyCouponsSuccess || []
          const primeCoupon = applyCouponsSuccess.filter(item => item.applyFor == 9 && item.typeId == 53) || []
          const xtraCoupon = applyCouponsSuccess.filter(item => item.typeId == 55) || []
          const newPrimeReadCoupon = primeCoupon.map(i => i.couponCode)
          const newXtraReadCoupon = xtraCoupon.map(i => i.couponCode)

          if (primeCoupon.length) {
            this.assignState({
              showShipSwitch: true,
            })
          }

          if (this.autoCouponInfo.show) return

          const isToastXtra = !!this.usedXtraCode && !!xtraCoupon.length
          // 自动用券后上报
          if (isToastXtra || primeCoupon.length) this.reportShippingCoupon()
          if (isXtra && isToastXtra) {
            if (typeof window != 'undefined') {
              this.$toast(this.template(xtraCoupon.length, this.language.SHEIN_KEY_PWA_25974))
            }
            this.assignState({
              autoXtraReadCoupon: newXtraReadCoupon
            })
            return
          }
          if (isToastXtra) {
            // 相同自动用券提示后 不在提示
            const isTips = this.autoXtraReadCoupon.toString() == newXtraReadCoupon.toString()
            if (!isTips) {
              let switchTips = this.template(xtraCoupon.length, this.language.SHEIN_KEY_PWA_25974)
              if (typeof window != 'undefined') {
                this.$toast(switchTips)
              }

              this.assignState({
                autoXtraReadCoupon: newXtraReadCoupon
              })
            }
          } else if (primeCoupon.length) {
            // 相同自动用券提示后 不在提示
            const isTips = this.autoPrimeReadCoupon.toString() == newPrimeReadCoupon.toString()
            if (!isTips) {
              let switchTips = this.template(primeCoupon.length, this.language.SHEIN_KEY_PWA_22733)
              if (typeof window != 'undefined') {
                this.$toast(switchTips)
              }

              this.assignState({
                autoPrimeReadCoupon: newPrimeReadCoupon
              })
            }
          } else {
            this.assignState({
              autoPrimeReadCoupon: [],
              autoXtraReadCoupon: []
            })
          }
        } else {
          if (typeof window == 'undefined') return
          // 首次自动用券，买券不需要提示
          if (isFirst || notTips) return
        }
      }
    },
    updateCouponRes(res, opt = {}) {
      if (res.code != '0') return

      const { coupon = [], couponGift = null } = res?.info || {}
      const { isFirst, check, isPrime, isXtra, isModifyCart } = opt
      const couponLth = coupon.length
      const lastCoupon = this.coupon.val
      const applyCoupons = coupon && coupon.map(item => item.couponCode.toLowerCase()) || []
      const lastCouponSuccess = applyCoupons.includes(lastCoupon.toLowerCase())

      // 记录点击券列表的inx
      if (check?.coupon && couponLth) {
        coupon.forEach(item => {
          if (isToLowerCase(item.couponCode, check.coupon)) {
            item.index = check.index
          }
        })
      }

      // 更新使用成功的券列表
      this.changeParamsStatus({
        type: 'coupon',
        params: {
          lastCoupon: lastCouponSuccess ? lastCoupon : '',
          success: !couponLth ? '' : shem_caculate_data.coupon,
          applyCouponsSuccess: coupon || [],
          applyCoupons,
          isAutoUse: !this.is_manual_usedCoupon
        }
      })

      // 更新券参数
      if (applyCoupons.length && !this.isCouponAutoUse) {
        shem_caculate_data.coupon = applyCoupons.join()
      }

      // couponGift 更新券赠品
      this.assignState({
        isShowCouponError: false,
        couponGift
      })

      // 使用 券赠品 的券 成功后打开赠品选择页 注意多mall场景第二次选择其他券的时候不需要打开
      if (isToLowerCase(lastCoupon, couponGift?.rules?.[0]?.couponCode) && !isPrime && !isXtra && !isModifyCart) {
        this.$nextTick(() => {
          this.changeParamsStatus({ type: 'pageStatus', params: { couponGiftAddItem: true, chooseCouponDrawer: false } })
        })
      }

      // 首次 不需要更新购物车，如果是券赠品 删券，和更换券都要更新购物车
      if (isFirst || isModifyCart) return
      this.updateCart()
    },
    checkCouponCost(res, opt) {
      const { isPrime, isXtra } = opt
      const { coupon = [], couponPrice } = res?.info || {}
      const couponLth = coupon?.length || 0
      const applyFor = coupon && coupon.filter(item => ['5', '6', '9'].includes(item.applyFor))
      const tempCost = applyFor.length ? this.language.SHEIN_KEY_PWA_15807 : couponPrice && couponPrice.amountWithSymbol ? '-' + couponPrice.amountWithSymbol : ''

      this.changeParamsStatus({
        type: 'coupon',
        params: {
          costShow: couponLth,
          cost: couponLth ? tempCost : '' // 免邮 减邮
        }
      })
      if (isPrime || isXtra) return
    },
    /**
     * @description 是否为未满足门槛券 reason = 4 or 9
     * @param {string} reason 
     * @returns {boolean}
     */
    isThresholdNotMet(reason) {
      return ['4', '9'].includes(reason)
    },
    /**
     * @description 是否展示 未满足门槛券 优惠券适用范围内的商品弹窗入口
     * @param {*} couponItem 
     * @returns {boolean}
     */
    showEntrance(couponItem = {}) {
      if (!this.isThresholdNotMet(couponItem?.reason)) return false
      // 适用范围商品不为空
      return !!couponItem?.apply_goods?.cart_coupon_goods_lists?.length
    },
    // 点击门槛提示
    handleClickThresholdTip(couponItem = {}) {
      // click_coupon_viewgoods
      daEventCenter.triggerNotice({
        daId: '1-11-1-94',
        extraData: {
          coupon_code: couponItem.couponCode || couponItem.coupon || '',
          is_available: '0',
        }
      })
      // 无入口
      if (!this.showEntrance(couponItem)) return
      this.showCouponLimitedDialog?.(couponItem)
    },

    // 更新场景
    updateSceneVirtual() {
      this.upDateSceneStatusNew()
      this.upDatePrimeSceneStatus()
    },
    // click_coupon上报
    clickCouponFn(extraData = {}) {
      daEventCenter.triggerNotice({
        daId: '1-11-1-131',
        extraData
      })
    }
  }
}
