<template>
  <section
    id="checkoutHeader"
    class="fixed-common-header mshe-z-header j-checkout-header"
  >
    <s-title-nav
      class="checkout-header"
      :style="{
        '--backgroundImage': `url('${checkout?.headerTitleBarAtmosphereData?.bgSrc ? checkout?.headerTitleBarAtmosphereData?.bgSrc : ''} ')`,
        '--fontColor': `${checkout?.headerTitleBarAtmosphereData?.color || ''}`
      }"
    >
      <template slot="preblock">
        <s-title-nav-item
          icon-class="iconfont-s icons-nav_back"
          @click.native="clickGoBackRouter('cart')"
        />
      </template>
      <template slot="default">
        <HeaderTitle />
      </template>
      <template slot="endblock">
        <a
          href="javascript:;"
          class="analysis-right-btn"
          @click="clickCustomerRobot"
        >
          <div
            class="order-ticketnum"
          >
            <i
              class="sui_icon_nav_support_24px"
              :class="[iconPrefix]"
            ></i>
            <span class="j-ticket-nopenum mshe-hide"></span>
          </div>
        </a>
      </template>
    </s-title-nav>
    <RetainDialog 
      v-if="asyncComponentLoadedStatus.retainDialogIsLoaded"
      ref="RetainDialog"
    />
  </section>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

import { 
  TitleNav as STitleNav, 
  TitleNavItem as STitleNavItem
} from '@shein/sui-mobile'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import HeaderTitle from './header/headerTitle.vue'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default {
  name: 'CheckoutHeader',
  components: {
    STitleNav,
    STitleNavItem,
    RetainDialog: () => import(/* webpackChunkName: "checkout-retain-dialog" */'./retain_dialog/index.vue'),
    HeaderTitle
  },
  computed: {
    ...mapState('checkout', ['language', 'checkout', 'asyncComponentLoadedStatus']),
    ...mapGetters('checkout', ['iconPrefix', 'isShopType']),
  },
  mounted(){
    setTimeout(()=>{
      this.changeParamsStatus({ type: 'asyncComponentLoadedStatus', params: { 
        loginConfirmIsLoaded: true,
        myCouponListIsLoaded: true,
        giftCardIsLoaded: true,
        retainDialogIsLoaded: true,
      }
      })
    }, 350)
  },
  methods: {
    ...mapMutations('checkout', ['changeParamsStatus']),
    clickGoBackRouter (type) {
      if( type == 'cart' ){
        if(this.$refs.RetainDialog) {
          this.$refs.RetainDialog.init()
          return
        }
        daEventCenter.triggerNotice({
          daId: '1-11-1-56',
          extraData: {
            is_shop: this.isShopType
          }
        })
      }
      if(this.$router) {
        history.back()
      } else {
        location.href = gbCommonInfo.langPath + '/cart'
      }
    },
    async clickCustomerRobot () {
      if (typeof sa !== 'undefined') {
        sa('send', {
          activity_name: 'click_support',
          activity_param: {}
        })
      }
      
      if (this.checkout?.results?.carts?.carts?.length) {
        let orderLogicIndex = await import('public/src/pages/common/orderLogic')
        let orderLogic = orderLogicIndex.default
        orderLogic.setToRobotPage({ mall_list: this.checkout.results.carts.carts, pageName: 'checkoutPage' })
      }
      const { jumpToRobot } = await import('@customerService/botLogic')
      jumpToRobot('checkoutPage', (data) => {
        if(this.$router){
          this.$routerPush(data)
        } else {
          location.href = data
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.fixed-common-header {
    position: fixed;
    left:0;
    top: 0;
    right: 0;
    width: 10rem;
    margin: 0 auto;
    transition: top .3s ease-out, transform .3s ease-out;
}
.mshe-z-header {
    .zindex-translatez(@zindex-header,translate3d(0,0,@tranlatez-header));
}
.mshe-hide {
    display: none !important; /* stylelint-disable-line declaration-no-important */
}


.checkout-header {
  background-image: var(--backgroundImage, @sui_color_white);
  background-size: 100% auto;
  background-repeat: no-repeat;
  a {
    text-decoration: none;
    color: var(--fontColor, @sui_color_gray_dark1);
  }
  /deep/ .S-title-nav__content-title {
    color: var(--fontColor, @sui_color_gray_dark1)
  }
}
.order-ticketnum {
	position: relative;
	> i {
		.font-dpr(32px);
	}
	> span {
		position: absolute;
		top: -.08rem;
		min-width: .22rem;
		height: .36rem;
		line-height: .36rem;
		margin: 0 .2rem;
		text-align: center;
		padding: 0 .07rem;
		box-sizing: content-box;
		.font-dpr(20px);
		.border-dpr(border,2px,#e5e5e5);
		border-radius: .58rem;
		color: #999;
		right: -.55rem;
	}
}
</style>
