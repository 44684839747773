<template>
  <div class="operate-title-container">
    <!-- 当为isShowTwoRowsTitle为true的时候，标题有两行 -->
    <h2
      v-if="isShowTwoRowsTitle"
      class="operate-title-container__main-title"
      :style="{color: showHeaderAtmosphere ? 'rgb(246, 246, 246)' : '#000'}"
    >
      {{ language.SHEIN_KEY_PWA_15245 }}
    </h2>
    <CheckoutCarousel
      ref="titleCarouselRef"
      :class="[isShowTwoRowsTitle ? 'operate-title-container__vice-title' : 'operate-title-container__single-title']"
      class="change-title"
      @callBackAfterAnimation="callBackAfterAnimation"
    >
      <template
        v-for="(_, index) in [0, 1]"
        #[`item${index}`]
      >
        <h2 
          :key="index"
          class="S-title-nav__content-title change-title__item"
        >
          <Icon
            v-for="(icon, iconIndex) in allIcons"
            v-show="titleConfig[titles[index].titleType]?.icon?.name === icon.name" 
            :key="iconIndex"
            :name="icon.name"
            :color="showHeaderAtmosphere ? '#fff' : icon.color"
            :size="isShowTwoRowsTitle ? '12px' : icon.size"
          />
          <div 
            class="change-title__text"
            :class="
              [
                isShowTwoRowsTitle ? 'change-title__vice-text' : 'change-title__single-text',
                !isShowTwoRowsTitle && titles[index].titleType !== headerTitleType.Normal ? 'change-title__text-small' : '',
              ]
            "
          >
            <span
              v-if="getTitleConfig(index).before"
              class="change-title__text_before"
              :class="isShowTwoRowsTitle ? 'change-title__text-normal' : ''"
              v-html="getTitleConfig(index).before"
            ></span>
            <CheckoutCarousel 
              v-if="getTitleConfig(index).special"
              :ref="`textCarouselRef${index}`"
              class="change-title__text_special"
            >
              <template
                v-for="(__, textIndex) in [0, 1]"
                #[`item${textIndex}`]
              >
                <span
                  :key="textIndex"
                  v-html="getTitleConfig(index, textIndex).special"
                ></span>
              </template>
            </CheckoutCarousel>
            <span
              v-if="getTitleConfig(index).after" 
              class="change-title__text_after"
              :class="isShowTwoRowsTitle ? 'change-title__text-normal' : ''"
              v-html="getTitleConfig(index).after"
            ></span>
          </div>
        </h2>
      </template>
    </CheckoutCarousel>
  </div> 
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { throttle, template, timeTransformer } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'
import CheckoutCarousel from './components/checkoutCarousel.vue'
import { formatDay2TimeStamp, quickShipTimeOut } from './common.js'
import { HeaderTitleType, TitleConfig, SaTypeMap, noReturnScenes, abtMap } from './config.js'
import { C_MONTH_FORMAT_MAP, C_YEAR_FORMAT_MAP }  from 'public/src/pages/common/biz_helper/timer_format/dictionary'
import { isProductRefundable } from 'public/src/pages/checkout/utils.js' 

export default {
  name: 'HeaderTitleOperateShow',
  components: {
    Icon,
    CheckoutCarousel
  },
  props: {
    isShowTwoRowsTitle: {
      type: Boolean,
      default: false // 默认是false，即方案show
    },
    lagreShipInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    const titles = this.isShowTwoRowsTitle ? 
      [{ moduleType: '', titleType: '', value: [] }, {}] : 
      [{ moduleType: HeaderTitleType.Normal, titleType: HeaderTitleType.Normal, value: [] }, {}]
    return {
      titles,
      headerHeight: 0,
      clientHeight: 0,
      isInitialized: false,
      headerTitleType: HeaderTitleType,
      titleConfig: TitleConfig,
      switching: false,
      autoPlayTimer: null,
      autoPlayQueue: [],
      autoPlayIndex: -1,
      isHasQuickShipTime: false, // 是否有时效运输时间
      headerQuickShip: {}
    }
  },
  
  computed: {
    ...mapState('checkout', [
      'checkout',
      'language',
      'defaultShipping',
      'price',
      'checkoutAbtInfo'
    ]),
    ...mapGetters('checkout', [
      'userBehaviorAbt', 
      'orderInterceptByAbt', 
      'showShippingInHeaderTitleByAbt',
      'SiteUID'
    ]),
    allIcons() {
      const icons = []
      Object.values(this.titleConfig).forEach(item => {
        if (!item.icon) return
        if (icons.find(iconItem => iconItem.name === item.icon.name)) return
        icons.push(item.icon)
      })
      return icons
    },
    currentIndex() {
      return this.$refs.titleCarouselRef?.currentIndex
    },
    nextIndex() {
      return this.$refs.titleCarouselRef?.nextIndex
    },
    currentTextIndex() {
      return this.$refs[`textCarouselRef${this.currentIndex}`]?.[0].currentIndex || 0
    },
    nextTextIndex() {
      return this.$refs[`textCarouselRef${this.currentIndex}`][0].nextIndex
    },
    showHeaderAtmosphere() {
      const { trade_event_atmosphere = '' } = this.checkoutAbtInfo.TradeEventAtmosphere?.param || {}
      return trade_event_atmosphere && trade_event_atmosphere !== 'NO'
    },
  },
  watch: {
    defaultShipping: {
      handler (val) {
        if (typeof window === 'undefined' || !this.isInitialized) return 
        // 关键信息切换
        const min = this.getFastestDeliveryDays(val)
        this.handleKeyTextChange(HeaderTitleType.Shipping, min)
      },
      immediate: true,
      deep: true
    },
    'price.saved_total_price': {
      handler (val) {
        if (typeof window === 'undefined' ||  !this.$refs.titleCarouselRef) return 
        // 关键信息切换
        this.handleKeyTextChange(HeaderTitleType.Saved, +val.amount)
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    this.initAutoPlay()
    this.handleHeadlineReport()
    this.handleTitleReport(HeaderTitleType.Normal)
    this.$nextTick(() => {
      this.handleInitHeight()
      this.setInitialTimer()
      this.isInitialized = true
    })
    window?.addEventListener('scroll', this.handlePageScroll)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('scroll', this.handlePageScroll)
    })
  },
  beforeDestroy() {
    // 清除定时器
    clearTimeout(this.autoPlayTimer)
    this.autoPlayTimer = null
  },
  methods: {
    template,
    callBackAfterAnimation() {
      this.$refs[`textCarouselRef${this.nextIndex}`]?.[0].reset()
      this.switching = false
    },
    /**
     * @description: 获取标题栏文案
     * @param {*} index
     * @param {*} textIndex
     * @return {*} before、special、after，其中special是滚动的文案
     */    
    getTitleConfig(index, textIndex = 0) {
      // 当前曝光的模块不满足条件时，将会展示兜底标题，因此有可能模块与标题不对应
      const { titleType, value } = this.titles[index]
      const config = this.titleConfig[titleType]

      if(!config) {
        return {}
      }

      let titleText = this.language[config.languageKey]
      let specialConfig

      // 目前动效文字就是高亮文字，不存在动效文字和高亮文字不是同一个多语言key的
      if (config.animation) {
        specialConfig = config.animation
      } else if (config.highlight) {
        specialConfig = config.highlight
      }
      if (!specialConfig) return { before: titleText }

      const val = value?.[textIndex] || ''
      let specialText = this.template(
        val,
        this.language[specialConfig.languageKey]
      )
      // 黑五活动期间，标题不需要特殊高亮颜色
      if (specialConfig.color && !this.showHeaderAtmosphere) {
        specialText = `<font color="${specialConfig.color}">${ specialText }</font>`
      }
      
      if (!config.languageKey) {
        return { special: specialText }
      }
      
      if(Object.keys(this.headerQuickShip).length && titleType == HeaderTitleType.Shipping){
        const { quick_ship_time, desc_quick_ship_time, qs_date_type, shipping_method, qs_standard_value } = this.headerQuickShip || {}
        const isQuickship = quick_ship_time || desc_quick_ship_time
        // 0自然日 1工作日
        const dateType = isQuickship ? qs_date_type : shipping_method?.date_type
        if(this.showShippingInHeaderTitleByAbt === 'type_A'){
          dateType == 1 && (specialText = `<font color="${specialConfig.color}">${template(value?.[textIndex] || 0, this.language.SHEIN_KEY_PWA_30752)}</font>`)
        } else if(this.showShippingInHeaderTitleByAbt === 'type_B'){ 
          const isQuickship =  (quick_ship_time || desc_quick_ship_time) && qs_standard_value ? true : false
          const time = isQuickship ? quick_ship_time : shipping_method?.shipping_time
          const daysText = dateType == 1 ? this.language[config.abtTypeLanguageKey?.work] : this.language[config.animation.languageKey]
          const specialText = `<font color="${specialConfig.color}">${ template(time?.split('-')?.[0], daysText) }</font>`
          const standard_value = shipping_method?.standard_value

          let percent
          // 无值不展示文案兜底
          if (isQuickship) {
            if(!qs_standard_value){
              return {}
            }else {
              [percent] = qs_standard_value.split('-')
            }
          }else {
            if(!standard_value){
              return {}
            }else {
              [percent] = standard_value.split('-')
            }
          }

          const special = template(`${percent}%`, specialText, this.language[config.abtTypeLanguageKey?.percent])
          return {
            before: '',
            special,
            after: ''
          }
        }else if (this.showShippingInHeaderTitleByAbt === 'type_C') {
          const hasNoQuickShipTime = this.checkout?.results?.carts?.carts?.filter(item => item.real_quick_ship != 1)?.length > 0 // 有非quickship时效商品
          const isNotQuickShip = this.headerQuickShip?.shipping_method?.expect_time && hasNoQuickShipTime
          const { desc_type, shipping_time, expect_time, date_type } = shipping_method
          let time
          let daysText
          let specialText
          let special
          if (isNotQuickShip) {
            if (desc_type == 0){
              time = `<font color="${specialConfig.color}">${shipping_time}</font>`
              special = date_type == 1 ? template(time, this.language[config.typeCLanguageKey?.workDay]) : template(time, this.language[config.typeCLanguageKey?.naturalDay])
            }else if (desc_type == 1){
              const isRangeTimeStamp = expect_time.indexOf('-') > -1
              if (isRangeTimeStamp) {
                const expect_time_arr = expect_time.split('-')
                const startTimeData = formatDay2TimeStamp(expect_time_arr[0])
                const endTimeData = formatDay2TimeStamp(expect_time_arr[1])
                //两段区间时间要和现在时间比都在同一年则展示月日，否则展示则展示年月日
                const sameYear = new Date(startTimeData).getFullYear() == new Date(endTimeData).getFullYear() && new Date().getFullYear() == new Date(startTimeData).getFullYear()
                
                time = 
                  `${timeTransformer({
                    time: startTimeData || '',
                    sDateMap: sameYear ? C_MONTH_FORMAT_MAP : C_YEAR_FORMAT_MAP,
                    defaultCode: sameYear ? 'D-3' : 'A-3'
                  })} ~ ${timeTransformer({
                    time: endTimeData || '',
                    sDateMap: sameYear ? C_MONTH_FORMAT_MAP : C_YEAR_FORMAT_MAP,
                    defaultCode: sameYear ? 'D-3' : 'A-3'
                  })}`
                const timeText = `<font color="${specialConfig.color}">${time}</font>`
                special = template(timeText, this.language[config.typeCLanguageKey?.rangeTimeStamp])
              }else {
                const timeStamp = formatDay2TimeStamp(expect_time)
                const sameYear = new Date(timeStamp).getFullYear() == new Date().getFullYear()
                //单个时间如果和现在时间比跨年则展示年月日，否则展示月日
                time = 
                  timeTransformer({
                    time: timeStamp || '',
                    sDateMap: sameYear ? C_MONTH_FORMAT_MAP : C_YEAR_FORMAT_MAP,
                    defaultCode: sameYear ? 'D-3' : 'A-3'
                  })
                const timeText = `<font color="${specialConfig.color}">${time}</font>`
                special = template(timeText, this.language[config.typeCLanguageKey?.singleTimeStamp])
              }
            }
          }else {
            time = quick_ship_time
            daysText = qs_date_type == 1 ? this.language[config.typeCLanguageKey?.workDay] : this.language[config.typeCLanguageKey.naturalDay]
            specialText = `<font color="${specialConfig.color}">${time}</font>`
            special = template(specialText, daysText)
          }
          return {
            before: '',
            special,
            after: ''
          }
        }
      }
      const slotIndex = titleText.indexOf('{0}')
      return {
        before: titleText.slice(0, slotIndex).replaceAll(' ', '&nbsp;'),
        special: specialText,
        after: titleText.slice(slotIndex + 3, titleText.length).replaceAll(' ', '&nbsp;')
      }
    },
    // 初次渲染埋点上报
    handleHeadlineReport() {
      const modules = Object.values(HeaderTitleType)
      const hasLabel = modules.some(module => {
        switch(module) {
          case HeaderTitleType.FreeReturn:
          case HeaderTitleType.SecureShipping:
            return this.autoPlayQueue.includes(module)
          default:
            return this.handlePreliminaryCheck(module).passed
        }
      })
     
      daEventCenter.triggerNotice({
        daId: '1-11-1-172',
        extraData: {
          scenes: 'headline_tips',
          type: hasLabel ? 3 : 1
        }
      })
    },
    // 当标题切换时，上报埋点
    handleTitleReport(type) {
      daEventCenter.triggerNotice({
        daId: '1-11-1-182',
        extraData: {
          headline_type: SaTypeMap[type]
        }
      })
    },
    /**
     * @description: 计算页面视窗、顶部标题栏的高度
     * @return {*}
     */    
    handleInitHeight() {
      const windowHeight = window.innerHeight || document.documentElement.clientHeight
      const headerHeight = document.getElementById('checkoutHeader')?.getBoundingClientRect().height
      const footerHeight = document.getElementsByClassName('checkout-summary-footer')?.[0]?.getBoundingClientRect().height
      this.headerHeight = headerHeight
      this.clientHeight =  windowHeight - footerHeight
    },
    /**
     * @description: 监听整个下单页面滚动触发的函数，在这里处理相关曝光的模块，更换对应的标题
     * @return {*}
     */    
    handlePageScroll: throttle({
      func: function () {
        if (!this.$refs.titleCarouselRef.finished || this.switching) return
        this.handleExposedModule()
      }, 
      wait: 800,
      options: {
        leading: true,
        trailing: true
      }
    }),
    initAutoPlay() {
      if (!this.isShowTwoRowsTitle) {
        const newCheckoutHeadlineAbt = this.userBehaviorAbt.NewCheckoutHeadline || ''
        const abtQueue = newCheckoutHeadlineAbt.split(',')
        abtQueue.forEach(item => {
          const titleType = abtMap[item]
          if (!titleType) return
          if (titleType === HeaderTitleType.FreeReturn) {
            // 只要任一不可退，即不满足‘免费退货’利诱点逻辑
            const notFreeReturn = this.checkout.results.carts.carts.some(item => (
              isProductRefundable(this.SiteUID, item)?.nonRefundable
            ))
            if (!notFreeReturn) this.autoPlayQueue.push(titleType)
            return
          }
          this.autoPlayQueue.push(titleType)
        })
      }
      this.autoPlayQueue.push(HeaderTitleType.Shipping)
      this.autoPlayIndex = 0
    },
    /**
     * @description: 当用户在1500秒内做了滚动等操作，视窗范围计算的就是0.4，否则就是整个视窗判断
     * @return {*}
     */    
    setInitialTimer() {
      this.autoPlayTimer = setTimeout(() => {
        const moduleType = this.autoPlayQueue[this.autoPlayIndex]
        const exposedModule = HeaderTitleType.Shipping
        if (this.handleFirstCheck(exposedModule)) {
          this.handleHeaderTitleChange(moduleType)
          if (this.autoPlayIndex + 1 <= this.autoPlayQueue.length - 1) {
            this.autoPlayIndex++
            this.setInitialTimer()
          }
        } else {
          this.handleExposedModule()
        }
      }, 1500)
    },
    /**
     * @description: 1500s内，没有做任何操作，判断当前模块是不是在整个视窗（除footer的高度）
     * @param {string} module 已曝光模块
     * @return {boolean} 是否满足曝光条件 true/false
     */    
    handleFirstCheck(module) {
      if (!this.headerHeight || !this.clientHeight) this.handleInitHeight()
      const condition = (top, bottom) => (
        top <= this.clientHeight && bottom >= this.headerHeight
      )
      const exposed = this.handleModuleExposeCheck(module, condition)
      return exposed
    },
    /**
     * @description: 处理满足检测需求的模块，此时的视窗需要乘以0.4。
     *               场景有两种：1、是滚动。2、1500s内，做了一些操作，触发该逻辑
     * @return {*}
     */    
    handleExposedModule() {
      const modules = [HeaderTitleType.Shipping, HeaderTitleType.Payment, HeaderTitleType.Saved]
      const { moduleType } = this.titles[this.currentIndex]
      const exposedModule = this.handleDoOperateCheck(modules)
      
      // 如果曝光的模块已经是当前模块，则不需要进行切换
      if (moduleType === exposedModule) return
      if (exposedModule) {
        // 一旦滚动到其他模块，轮播停止
        clearTimeout(this.autoPlayTimer)
        this.handleHeaderTitleChange(exposedModule)
      }
    },
    /**
     * @description: 做了一些操作后的检测，需要到达视窗高度的40%
     * @param {array} modules 模块
     * @return {string} 满足曝光条件的模块
     */    
    handleDoOperateCheck(modules) {
      const boundary = (window.innerHeight || document.documentElement.clientHeight) * 0.4
      const condition = (top, bottom) => top <= boundary && boundary <= bottom
      const exposedModule = modules.find(module => (
        this.handleModuleExposeCheck(module, condition)
      ))
      return exposedModule
    },
    /**
     * @description: 检查某个模块是否曝光
     * @param {string} module 模块
     * @param {funtion} condition 曝光条件，是一个函数
     * @return {boolean} 是否满足曝光条件 true/false
     */     
    handleModuleExposeCheck(module, condition) {
      if (!module || !condition) return false
      const elements = [...document.getElementsByClassName(`checkout-scroll__${module.toLocaleLowerCase()}`)]
      const exposed = elements.some(element => {
        const elementRect = element.getBoundingClientRect()
        const elementTop = elementRect.top
        const elementBottom = elementRect.bottom
        return condition(elementTop, elementBottom)
      })
      return exposed
    },
    /**
     * @description: 标题切换
     * @param {string} module 已曝光的模块类型
     * @return {*}
     */    
    handleHeaderTitleChange(module) {
      const { title, value } = this.getDisplayedTitle(module)
      const { titleType } = this.titles[this.currentIndex]

      // 曝光模块更新，标题不变。
      // 如shipping展示了兜底标题（支付安全保障），当payment模块曝光后，标题不需要切换，仅数据更新
      if (titleType === title) {
        const config = [...this.titles]
        config[this.currentIndex] = {
          ...this.titles[this.currentIndex],
          moduleType: module,
        }
        this.titles = config
        return
      }
      this.playTitleAnimation(module, title, value)
    },
    /**
     * @description: 获取可以用于切换的标题
     * @param {string} module 已曝光的模块类型
     * @return {string} 切换的标题类型
     */    
    getDisplayedTitle(module) {
      const preCheck = this.handlePreliminaryCheck(module)
      if (preCheck.passed) {
        return {
          title: module,
          value: preCheck.value
        }
      } else {
        // 异常情况，当前模块不满足条件，则展示‘支付安全保障’文案
        return { 
          title: HeaderTitleType.Payment
        }
      }
    },
    /**
     * @description: 标题切换后触发相关的动画
     * @param {string} module 此时曝光的模块类型
     * @param {string} title 需要切换的标题类型
     * @param {string} value 当前对应的值
     */
    playTitleAnimation(module, title, value) {
      this.switching = true
      const config = [...this.titles]
      config[this.nextIndex] = {
        moduleType: module,
        titleType: title,
        ...(value ? { value: [value] } : {})
      }
      this.titles = config
      this.$refs.titleCarouselRef.playAnimation()
      // 埋点上报
      this.handleTitleReport(title)
    },
    
    /**
     * @description: 标题中的关键词切换
     * @param {string} type 需要切换的标题类型
     * @param {string} val 切换的值
     * @return {*}
     */    
    handleKeyTextChange(type, val) {
      const { moduleType, titleType, value } = this.titles[this.currentIndex]
      // 如果当前模块类型与需要更新信息的类型不一致，则不需要处理
      if (moduleType !== type) {
        return
      }
      const currentVal = value?.[this.currentTextIndex]
      if (titleType === type && val !== currentVal) {
        const preCheck = this.handlePreliminaryCheck(type)
        if (preCheck.passed) {
          const config = [...this.titles]
          config[this.currentIndex].value[this.nextTextIndex] = val
          this.titles = config
          this.$refs[`textCarouselRef${this.currentIndex}`][0].playAnimation()
        } else {
          this.playTitleAnimation(type, HeaderTitleType.Payment, val)
        }
      } else if (titleType !== type) {
        this.handleHeaderTitleChange(type)
      }
    },
    /**
     * @description: 标题切换前的检测（检查能否展示模块对应的标题）
     * @param {string} type 曝光类型
     * @return {*}  是否满足标题展示条件，若不满足，则需要展示兜底方案
     */    
    handlePreliminaryCheck(type) {
      switch(type) {
        case HeaderTitleType.Shipping:
          return this.handleShippingCheck()
        case HeaderTitleType.Saved:
          return {
            passed: +this.price?.saved_total_price?.amount > 0,
            value: +this.price?.saved_total_price?.amount
          }
        default:
          return { passed: true }
      }
    },
    /**
     * @description: 运输方式的时间进行检测，1、ab如果为off，直接返回 2、当前订单有大件商品的时候，不展示运输方式时效
     * @return {object} { passed: 标签tag, value: 运输方式的最快时间 } 
     */    
    handleShippingCheck() {
      if (this.showShippingInHeaderTitleByAbt === 'off' || !!this.lagreShipInfo?.largeShipGoods?.length) {
        return { passed: false }
      }
      const min = this.getFastestDeliveryDays(this.defaultShipping)
      if (Number.isNaN(+min) || min <= 0 ) {
        // 最小送达天数 <= 0 或 没有默认运输方式（min为undefined）
        return { passed: false }
      }
      const APOLLO_ARRIVAL_DATE = this.checkout.APOLLO_ARRIVAL_DATE || 10
      // 按照headerQuickShip里的校验值跟 阿波罗配置的时间阈值比较，不符合则不展示文案
      if(this.headerQuickShip?.checkValue && this.headerQuickShip?.checkValue > APOLLO_ARRIVAL_DATE){
        return { passed: false }
      }
      return { passed: true, value: min }
    },
    /**
     * @description: 获取最快送达时间
     * @param {array} shippingMethods 当前选中的运输方式
     * @return {number} 最快送达时间
     */    
    getFastestDeliveryDays(shippingMethods) {
      if(!shippingMethods?.length) {
        this.headerQuickShip = {}
        return 0
      }
      if (this.showShippingInHeaderTitleByAbt === 'type_A') {
        return this.handleAbtIsTypeA(shippingMethods)
      }
      if (this.showShippingInHeaderTitleByAbt === 'type_B') {
        return this.handleAbtIsTypeB(shippingMethods)
      }
      if (this.showShippingInHeaderTitleByAbt === 'type_C'){
        return this.handleAbtIsTypeC(shippingMethods)
      }
      const interceptList = [0, 1, 2, 3, 7]
      let min
      // 多mall情况下
      shippingMethods?.forEach(item => {
        const { shipping_method } = item
        if (!shipping_method) return
        const { is_available, is_available_msg, fastest_delivery_days, rule_info } = shipping_method
        const isDisabled = is_available == 0
        const isDisabledMsg = is_available_msg == 9
        const case1 = !isDisabledMsg
        const case2 = this.orderInterceptByAbt && isDisabledMsg && interceptList.includes(+rule_info?.hint_type)
        const isOrderIntercept = isDisabled && (case1 || case2)
        const isFirst = typeof min === 'undefined'
        const isMin = fastest_delivery_days && +fastest_delivery_days < min
        if (!isOrderIntercept && (isFirst || isMin)) min = +fastest_delivery_days
      })
      return min
    },
    
    handleAbtIsTypeA(shippingMethods) {
      const isQuickShipTime = shippingMethods.some(s => s?.quick_ship_time || s?.desc_quick_ship_time)

      if(isQuickShipTime){
        const _shippingMethods = shippingMethods.filter(f => {
          return f.quick_ship_time
        })
        if (_shippingMethods.length == 0){
          this.headerQuickShip = {}
          return 0
        }
        const minObj = _shippingMethods.reduce((min, current) => {
          // min 上一个值，current 当前值
          const resultCurrent = quickShipTimeOut(current)
          const resultMin = quickShipTimeOut(min)
          if(resultCurrent == resultMin){
            const isAllNaturalDay = current?.qs_date_type == 0 && min?.qs_date_type == 0
            if(isAllNaturalDay){
              return current?.quick_ship_time.indexOf('-') == -1 ? current :  min
            } else {
              return current?.qs_date_type == 0 
                ? current 
                : min?.qs_date_type == 0 ? min : current?.quick_ship_time.indexOf('-') == -1 ? current :  min
            }
          }
          return resultCurrent < resultMin ? current : min
        }, _shippingMethods[0])
        const min = quickShipTimeOut(minObj)
        this.headerQuickShip = minObj
        this.headerQuickShip.checkValue = min
        // console.log('minObj', minObj, min)

        return min
      }else{
        const _shippingMethods = shippingMethods.filter(f => {
          return f?.shipping_method?.expect_time
        })
        if (_shippingMethods.length == 0){
          this.headerQuickShip = {}
          return 0
        }
        const minObj = _shippingMethods.reduce((min, current) => {
          const resultCurrent = quickShipTimeOut(current, 'expect_time')
          const resultMin = quickShipTimeOut(min, 'expect_time')
          if(resultCurrent == resultMin){
            return current?.shipping_method?.expect_time?.indexOf('-') == -1 ? current :  min
          }
          return resultCurrent < resultMin ? current : min
        }, _shippingMethods[0])
        // 非qs，比较值还是expect_time，但展示的取值改为shipping_time
        const min = quickShipTimeOut(minObj, 'shipping_time')
        this.headerQuickShip = minObj
        this.headerQuickShip.checkValue = quickShipTimeOut(minObj, 'shipping_time')
        // console.log('minObj', minObj, min)

        return min
      }
    },
    handleAbtIsTypeB(shippingMethods) {
      const isQuickShipTime = shippingMethods.some(s => (s?.quick_ship_time || s?.desc_quick_ship_time) && s?.qs_standard_value)
      const { APOLLOPERCENT } = this.checkout
      if(isQuickShipTime){
        //阿波罗增加时效到达百分比阈值开关，过滤出时效百分比（qs_standard_value）<阿波罗配置的时效百分比阈值 + 无quick_ship_time字端不参与时效比较
        let _shippingMethods = shippingMethods.filter((f) => { 
          if (f?.qs_standard_value && f?.quick_ship_time){
            const [ qs_standard_value ] = f?.qs_standard_value.split('-')
            return qs_standard_value >= APOLLOPERCENT
          }
          return false
        })
        if (_shippingMethods.length == 0){
          this.headerQuickShip = {}
          return 0
        }
        const minObj = _shippingMethods.reduce((min, current) => {
          const resultCurrent = quickShipTimeOut(current)
          const resultMin = quickShipTimeOut(min)

          if(resultCurrent == resultMin){
            const isAllNaturalDay = current?.qs_date_type == 0 && min?.qs_date_type == 0
            if(isAllNaturalDay){
              return current?.quick_ship_time.indexOf('-') == -1 ? current :  min
            } else {
              return current?.qs_date_type == 0 
                ? current 
                : min?.qs_date_type == 0 ? min : current?.quick_ship_time.indexOf('-') == -1 ? current :  min
            }
          }
          return resultCurrent < resultMin ? current : min
        }, _shippingMethods[0])
        const min = quickShipTimeOut(minObj)
        this.headerQuickShip = minObj
        this.headerQuickShip.checkValue = min
        return min
      }else{
        //过滤出无时效百分比(standard_value) < 阿波罗配置的时效百分比阈值 && expect_time为null时也应该过滤掉，不参与比较
        let _shippingMethods = shippingMethods.filter((f) => { 
          return f?.shipping_method?.standard_value >= APOLLOPERCENT && f?.shipping_method?.expect_time
        })
        if (_shippingMethods.length == 0){
          this.headerQuickShip = {}
          return 0
        }
        const minObj = _shippingMethods.reduce((min, current) => {
          const resultCurrent = quickShipTimeOut(current, 'expect_time')
          const resultMin = quickShipTimeOut(min, 'expect_time')

          if(resultCurrent == resultMin){
            return current?.shipping_method?.expect_time?.indexOf('-') == -1 ? current :  min
          }
          return resultCurrent < resultMin ? current : min
        }, _shippingMethods[0])
        const min = quickShipTimeOut(minObj, 'shipping_time')
        this.headerQuickShip = minObj
        this.headerQuickShip.checkValue = quickShipTimeOut(minObj, 'shipping_time')
        return min
      }
    },

    handleAbtIsTypeC(shippingMethods) {
      const hasNoQuickShipTime = this.checkout?.results?.carts?.carts?.filter(item => item.real_quick_ship != 1)?.length > 0 // 有非quickship时效商品
      const notQuickShipTimeMethods = shippingMethods.filter(s => { 
        return s?.shipping_method?.expect_time
      })
      //优先取非quickship时效，这里和产品沟通了指优先取expect_time时效值；如果expect_time都没有值，就走quickship时效；如果quick_ship_time也没值，就返回0不展示
      if(hasNoQuickShipTime && notQuickShipTimeMethods.length){
        const maxObj = shippingMethods.reduce((max, current) => {
          const resultCurrent = quickShipTimeOut(current, 'expect_time')
          const resultMax = quickShipTimeOut(max, 'expect_time')
          //时效一样时取区间时间类型的
          if(resultCurrent == resultMax){
            return current?.shipping_method?.expect_time?.indexOf('-') == -1 ? max :  current
          }
          return resultCurrent > resultMax ? current : max
        }, shippingMethods[0])
        const max = quickShipTimeOut(maxObj, 'shipping_time')
        this.headerQuickShip = maxObj
        this.headerQuickShip.checkValue = quickShipTimeOut(maxObj, 'shipping_time')
        return max
      }else{
        //过滤掉无quick_ship_time的项（此时走仅有quickship时效逻辑
        const _shippingMethods = shippingMethods.filter(s => { 
          return s?.quick_ship_time
        })
        if (_shippingMethods.length == 0) {
          return 0
        }
        const maxObj = _shippingMethods.reduce((max, current) => {
          // min 上一个值，current 当前值
          const resultCurrent = quickShipTimeOut(current)
          const resultMax = quickShipTimeOut(max)
 
          if(resultCurrent == resultMax){
            const isAllNaturalDay = current?.qs_date_type == 0 && max?.qs_date_type == 0
            if(isAllNaturalDay){
              return current?.quick_ship_time.indexOf('-') == -1 ? max :  current
            } else {
              return current?.qs_date_type == 1 
                ? current 
                : max?.qs_date_type == 1 ? max : current?.quick_ship_time.indexOf('-') == -1 ? current :  max
            }
          }
          return resultCurrent > resultMax ? current : max
        }, _shippingMethods[0])
        const max = quickShipTimeOut(maxObj)
        this.headerQuickShip = maxObj
        this.headerQuickShip.checkValue = max
        return max
      }
    },
  }
}
</script>
<style lang="less" scoped>
.operate-title-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__main-title {
    width: 279px;
    height: 38/75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    .font-dpr(32px);
    font-weight: 700;
    line-height: 1.2;
  }
  &__vice-title {
    width: 279px;
    height: 26/75rem;
    .font-dpr(22px);
  }
  &__single-title {
    // width: 279px;
    width: 100;
    height: 100%;
  }
}
.change-title {
  &__item {
    display: flex;
    justify-items: center;
    align-items: center;
  }
  &__text {
    display: flex;
    align-items: center;
    text-transform: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    margin-left: 4/75rem;
    line-height: 1.2;
    &_special {
      height: 100%;
    }
  }
  &__text-normal {
    font-weight: 400;
  }
  &__single-text {
    height: 40/75rem;
    .font-dpr(32px);
  }
  &__vice-text {
    height: 26/75rem;
    .font-dpr(22px);
  }
  &__text-small {
    .font-dpr(28px);
    height: 34/75rem;
  }
}
</style>
