const eventCategory = '下单页'
import { judgeActivityIsExist } from '../utils'

const analysisCheckoutEvent = {

  sendToBi (opt) {
    if (typeof (sa) === 'function') {
      if (!opt) return

      const _data = {}

      if (opt.name) _data.activity_name = opt.name
      if (opt.param) _data.activity_param = opt.param

      sa('send', _data)
    }
  },
  // 初始化一次即可
  bindEvent: function () {
    // 点击ChooseAnotherAddress展开页面点击Edit 具体地址的按钮
    $(document).on('click.ga', '.j-edit', function () {
      sa('send', { activity_name: 'expose_edit_address_form' })
    })
    // 选中支付方式
    var _paypalLoading = false
    $(document).on('click.ga', '.j-payment-item', function () {
      if (_paypalLoading) return
      _paypalLoading = true

      setTimeout(function () {
        _paypalLoading = false
      }, 300)
    })
  },
  saveAddress_sa: function (result, reason, tag) {
    sa('send', { activity_name: 'click_add_a_shipping_address', activity_param: { result: result, result_reason: reason || '', associationAddress: +tag === 2 ? 'Y' : 'N' } })
  },
  editAddress: function (result, reason, tag) {
    sa('send', { activity_name: 'click_edit_a_shipping_address', activity_param: { result: result, result_reason: reason || '', associationAddress: +tag === 2 ? 'Y' : 'N' } })
  },
  sendBi: function ({ 
    carts, 
    payment_method_id = '', 
    coupon, 
    points, 
    result, 
    result_reason, 
    order_id, 
    is_deful_shipping_method, 
    is_use_shipping_insurance, 
    is_use_coupon, 
    address_type, 
    order_id_list = '', 
    promotion = '', 
    mall_shipping_method_id = '', 
    is_choose_foldedpayment = 0, 
    prime_level = 0, 
    prime_deduction = 0, 
    prime_product_id = '', 
    shipping_marked_price = 0, 
    discount_hint = '', 
    freeshipping_tp = '', 
    shipping_time_desc = '', 
    final_qs_freight_status = '-', 
    is_satisfied_qs = '-', 
    coupon_method = '-', 
    Saver = '-', 
    is_vaulting = '', 
    transaction_activity, 
    coupon_info, 
    is_payment_default, 
    is_shipping_default, 
    product_type,
    default_pay_mtd = '-',
    selected_pay_mtd = '-',
    check_out_scene = '-',
  } = {}) {
    (coupon == undefined || coupon == '') ? coupon = '0' : coupon = '1';
    (points == undefined || points == '0') ? points = '0' : points = '1'
    
    let is_insured_goods = carts.find(item => item.product.insurable)
    let storeInfo = [] // 店铺类型`店铺ID`商品数量
    let mallCodeSet = new Set()
    let overTypeSet = new Set()
    carts.forEach(cartItem => {
      let store = storeInfo.find(item => item.store_code == cartItem.store_code)
      if(store) {
        store.productNum += cartItem.quantity
      } else {
        storeInfo.push({
          store_code: cartItem.store_code,
          store_type: cartItem.store_type,
          productNum: cartItem.quantity,
          mall_code: cartItem.mall_code
        })
      }
      mallCodeSet.add(cartItem.mall_code)
      if(cartItem.normalFlashSale?.limit_mode == 1){
        if(cartItem.normalFlashSale.single_over == 1) { overTypeSet.add('single') }
        if(cartItem.normalFlashSale.member_over == 1) { overTypeSet.add('total') }
      }
    })

    // quickship_tp：当下单商品中无quickship商品时传0，当全部是quickship时传1，当部分quickship商品时传2
    const quickshipCarts = carts.filter(item => item.real_quick_ship == 1) || []
    let quickship_tp = !quickshipCarts.length ? '0' : (quickshipCarts.length == carts.length ? '1' : '2')
    let overType = overTypeSet.size ? overTypeSet.size == 2 ? 0 : overTypeSet.has('single') ? 'single' : 'total' : 0
    let label_id = 0
    if(typeof window != 'undefined'){
      label_id = sessionStorage.getItem('filterType')
      if(label_id == 'none' || !label_id) label_id = 0
    }
    const payment_method = $('label[name=paymentmethod]>input:checked').parents('.j-payment-item').data('method') || ''


    const commonParams = {
      mall_shipping_recommend: is_deful_shipping_method,
      mall_freight_insurance: is_use_shipping_insurance,
      coupon_use: is_use_coupon,
      payment_method,
      payment_code: payment_method,
      address_type,
      promotion: promotion,
      version: '1', // 1（代表新样式）、2（代表老样式）
      is_choose_foldedpayment,
      prime_level,
      prime_deduction,
      prime_product_id,
      is_insured_goods: is_insured_goods ? 1 : 0,
      shipping_marked_price,
      store_info: storeInfo.map(item => `${item.store_type}\`${item.store_code}\`${item.productNum}\`${item.mall_code}`).join(','),
      mall_code: [...mallCodeSet].join(','),
      quickship_tp,
      discount_hint,
      freeshipping_tp,
      over_type: overType,
      final_qs_freight_status,
      is_satisfied_qs,
      Saver,
      is_vaulting,
      ...(transaction_activity ? { transaction_activity } : {}),
      ...(coupon_info ? { coupon_info } : {}),
    }
    sa('send', {
      activity_name: 'click_place_order',
      activity_param: {
        shipping_time_desc,
        coupon_method,
        label_id,
        is_payment_default,
        is_shipping_default,
        product_type,
        check_out_scene,
        ...commonParams,
      }
    }, { beacon: 1 })
    sa('send', {
      activity_name: 'click_place_order_result',
      activity_param: {
        payment_method_id: payment_method_id + '',
        points: points, // points > 0   使用
        result: result,
        result_reason: result_reason,
        order_id: order_id,
        uorder_id: order_id,
        order_id_list,
        activity_method: judgeActivityIsExist(carts, [3, 10]),
        mall_shipping_method_id,
        default_pay_mtd,
        selected_pay_mtd,
        ...commonParams,
      }
    }, { beacon: 1 })
  }
}

typeof window !== 'undefined'  &&  (window.analysisCheckoutEvent = analysisCheckoutEvent)

export default analysisCheckoutEvent
