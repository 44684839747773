const HeaderTitleType = {
  // 普通标题
  Normal: 'Normal',
  // 最快送达时间
  Shipping: 'Shipping',
  // 支付安全保障
  Payment: 'Payment',
  // 组合优惠信息
  Saved: 'Saved',
  // 免费退货
  FreeReturn: 'FreeReturn',
  // 丢件必赔
  SecureShipping: 'SecureShipping',
}

const TitleConfig = {
  [HeaderTitleType.Normal]: {
    languageKey: 'SHEIN_KEY_PWA_15245',
  },
  [HeaderTitleType.Shipping]: {
    icon: {
      name: 'sui_icon_shipping_18px',
      color: '#198055',
      size: '18px'
    },
    languageKey: 'SHEIN_KEY_PWA_30754',
    animation: {
      languageKey: 'SHEIN_KEY_PWA_27757',
      color: '#198055'
    },
    abtTypeLanguageKey: {
      work: 'SHEIN_KEY_PWA_30752',
      percent: 'SHEIN_KEY_PWA_30753'
    },
    typeCLanguageKey: {
      singleTimeStamp: 'SHEIN_KEY_PWA_31490', // Arrives at {0}
      rangeTimeStamp: 'SHEIN_KEY_PWA_31559', // Arrives between {0}
      workDay: 'SHEIN_KEY_PWA_31488', // Arrives between {0} Business days 
      naturalDay: 'SHEIN_KEY_PWA_31489' // Arrives between {0} days
    }
  },
  [HeaderTitleType.Payment]: {
    icon: {
      name: 'sui_icon_guarantee_18px',
      color: '#198055',
      size: '18px'
    },
    highlight: {
      languageKey: 'SHEIN_KEY_PWA_27695',
      color: '#198055'
    }
  },
  [HeaderTitleType.Saved]: {
    icon: {
      name: 'sui_icon_random_discount_16px',
      color: '#FA6338',
      size: '18px'
    },
    languageKey: 'SHEIN_KEY_PWA_27696',
    animation: {
      languageKey: 'SHEIN_KEY_PWA_27697',
      color: '#FA6338'
    }
  },
  [HeaderTitleType.FreeReturn]: {
    icon: {
      name: 'sui_icon_shipping_18px',
      color: '#198055',
      size: '18px'
    },
    languageKey: 'SHEIN_KEY_PWA_29733',
    highlight: {
      languageKey: 'SHEIN_KEY_PWA_29734',
      color: '#198055'
    }
  },
  [HeaderTitleType.SecureShipping]: {
    icon: {
      name: 'sui_icon_shipping_18px',
      color: '#198055',
      size: '18px'
    },
    languageKey: 'SHEIN_KEY_PWA_29735'
  }
}

const SaTypeMap = {
  [HeaderTitleType.Normal]: 'default_status',
  [HeaderTitleType.Shipping]: 'shipping_time',
  [HeaderTitleType.Payment]: 'payment_protect',
  [HeaderTitleType.Saved]: 'saving_discount',
  [HeaderTitleType.FreeReturn]: 'free_return',
  [HeaderTitleType.SecureShipping]: 'secure_shipment'
}

const ReturnFlag = {
  // 客服不可退
  CustomerServiceNoReturn: 1,
  // 商品不可退
  ProductNoReturn: 2,
  // 活动不可退
  ActivityNoReturn: 3,
}

// 不可退货的场景
const noReturnScenes = [
  ReturnFlag.CustomerServiceNoReturn,
  ReturnFlag.ProductNoReturn,
  ReturnFlag.ActivityNoReturn
]

const abtMap = {
  'freereturn': HeaderTitleType.FreeReturn,
  'secure': HeaderTitleType.SecureShipping
}

export {
  HeaderTitleType,
  TitleConfig,
  SaTypeMap,
  noReturnScenes,
  abtMap
}
